import React, { useContext, useEffect, useState, useRef } from 'react'
import OrderContext from '../contexts/OrderContext'
import { Category } from '../interfaces/IceCreamTypes'
import { categoryItems } from '../constants/categoryItems'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
interface ICategory {
    title: string
    // add other properties if they exist
}
interface ICat {
    item: ICategory
    index: number
}

interface QuickMenuTwoProps {
    children?: any
}

function QuickMenu() {
    const { chosenCategory, setChosenCategory } = useContext(OrderContext)
    const [activeIndex, setActiveIndex] = useState(0)
    const sliderRef = useRef<any | null>(null)
    const indexRef = useRef<HTMLLIElement>(null)
    function findCategory(title: string) {
        for (let [i, item] of categoryItems.entries()) {
            if (item.title === title) {
                let cat: ICat = {
                    item: item,
                    index: i
                }
                cat.item = item

                cat.index = i
                return cat
            }
        }
    }

    function switchCategory(title: string, index: number) {
        const cat = findCategory(title)
        let catObj = cat!.item
        // sliderRef.current.goTo(index)
        // setActiveIndex(cat!.index)
        setChosenCategory(catObj)
    }

    useEffect(() => {
        const activeElement = document.querySelector('.active-category')

        activeElement?.scrollIntoView({ behavior: 'smooth' })
    }, [])

    return (
        <>
            <div className="container pt-3 pb-0">
                <div className="row justify-content-between">
                    <div className="col">
                        <ul className="quick-nav-list">
                            <Swiper
                                spaceBetween={25}
                                slidesPerView={3}
                                onSwiper={(swiper: any) => {}}
                            >
                                {Object.entries(Category).map(
                                    ([key, value], index) => (
                                        <SwiperSlide key={key}>
                                            <li
                                                onClick={() =>
                                                    switchCategory(value, index)
                                                }
                                                className={
                                                    value ===
                                                    chosenCategory?.title
                                                        ? 'active-category'
                                                        : ''
                                                }
                                                key={key}
                                            >
                                                <p>{value}</p>
                                            </li>
                                        </SwiperSlide>
                                    )
                                )}
                            </Swiper>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuickMenu
