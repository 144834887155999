import { useContext, useEffect, useState } from 'react'
import CardDoneOrder from '../components/CardDoneOrder'
import OrderContext from '../contexts/OrderContext'
import { IOrder } from '../interfaces/i-order'
import { IPaymentRequest, PaymentRequestStatus } from '../interfaces/i-payment'
import { Category, GuteSnurr } from '../interfaces/IceCreamTypes'
import { PaymentResultErrorCodes } from '../interfaces/SwishErrorCodes'
import orderService from '../services/orderService'
import swishService, { getErrorMessage } from '../services/swishService'

function Receipt() {
    const { setOrder, setSelectedItems, order } = useContext(OrderContext)
    const [loading, setLoading] = useState(false) // eslint-disable-line
    const [error, setError] = useState<Error | undefined>() // eslint-disable-line
    const [paymentRequest, setPaymentRequest] = useState<
        IPaymentRequest | undefined
    >()
    const [ready, setReady] = useState(false)

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            getPaymentRequest()
        }, 2000)
    }, []) // eslint-disable-line

    const getOrder = async (id: string) => {
        return await orderService.getOrder(id!)
    }

    const getId = async () => {
        let id: string | null
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        id = urlParams.get('ref')
        return id
    }

    // kolla om order är klar
    useEffect(() => {
        setInterval(async () => {
            const id = await getId()
            if (id !== null) {
                let order: IOrder = await getOrder(id)
                if (order.isReady === true) {
                    setReady(true)
                }
            }
        }, 30000)
    }, [])

    const getPaymentRequest = async () => {
        setError(undefined)
        let id = await getId()
        if (id !== null) {
            const last = id.charAt(id.length - 1)
            if (last === '/') {
                id = id.replace(id.charAt(id.length - 1), '')
            }
        }

        try {
            const orderValues = await getOrder(id!)
            if (orderValues.isReady === true) {
                setReady(true)
            }
            let response: IPaymentRequest

            response = await swishService.getPaymentRequest(id!)

            let typedColorString =
                response.errorCode as keyof typeof PaymentResultErrorCodes
            let typedColor = PaymentResultErrorCodes[typedColorString]
            if (response.errorCode) {
                response.errorMessage = getErrorMessage(typedColor)
            }
            if (response.status === PaymentRequestStatus.Paid) {
                await submit(orderValues)
            }
            setPaymentRequest(response)
            setLoading(false)
        } catch (e: any) {
            setError(new Error(e))
            console.log(e)
            setLoading(false)
        }
        setLoading(false)
    }

    const submit = async (orderValues: IOrder) => {
        orderValues.isPaid = true
        let ord = await orderService.updateOrder(orderValues)
        setOrder(ord)
        setSelectedItems([])
        setLoading(false)
    }

    return (
        <div className="startpage container">
            <div className="complete view pt-3">
                {loading ? (
                    <div className="loader-outer d-flex justify-content-center align-items-center">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <>
                        {error ? (
                            <div className="error-msg p-2">{error.message}</div>
                        ) : (
                            <>
                                <div className="my-4">
                                    {paymentRequest?.status ===
                                        PaymentRequestStatus.Error && (
                                        <>
                                            <div>ERROR</div>
                                            {/* sätt error msg från getErrorMessage */}
                                            <div>
                                                {paymentRequest.errorMessage}
                                            </div>
                                        </>
                                    )}
                                    {paymentRequest?.status ===
                                        PaymentRequestStatus.Declined && (
                                        <>
                                            <div>Betalningen avvisades</div>
                                            <div>
                                                Betalaren avböjde att genomföra
                                                betalningen, gå tillbaka och gör
                                                en ny beställning eller beställ
                                                i kassan.
                                            </div>
                                        </>
                                    )}
                                    {paymentRequest?.status ===
                                        PaymentRequestStatus.Cancelled && (
                                        <>
                                            <div>Betalningen avbröts</div>
                                            <div>
                                                Betalningsförfrågan avbröts,
                                                antingen av handlaren eller av
                                                betalaren via handlarens
                                                webbplats, gå tillbaka och gör
                                                en ny beställning eller beställ
                                                i kassan.
                                            </div>
                                        </>
                                    )}
                                </div>
                                {paymentRequest?.status ===
                                    PaymentRequestStatus.Paid && (
                                    <>
                                        <h1>Tack för din beställning!</h1>
                                        <div className="my-2">
                                            Vi kommer ut med din beställning när
                                            den är redo och ropar upp ditt namn
                                        </div>
                                        <div className="mb-2">
                                            Ditt ordernummer är
                                        </div>
                                        <CardDoneOrder
                                            item={order!}
                                            text={
                                                ready
                                                    ? 'Klar att hämta'
                                                    : 'Förbereds'
                                            }
                                            icon={
                                                ready
                                                    ? 'img/ikon-klar.svg'
                                                    : 'img/ikon-forbereds.svg'
                                            }
                                            ready={ready}
                                        />
                                        <div className="chosen-items mt-3 mb-5">
                                            <div className="order-heading mb-2">
                                                Din beställning
                                            </div>
                                            {order?.items?.map((i, key) => {
                                                if (
                                                    i.category ===
                                                        Category.SoftIceCream ||
                                                    (i.category ===
                                                        Category.GuteSnurr &&
                                                        i.guteSnurrFlavor ===
                                                            GuteSnurr.Customized)
                                                ) {
                                                    return (
                                                        <div
                                                            key={key}
                                                            className="item py-2 softicecream d-flex justify-content-between"
                                                        >
                                                            <div className="d-flex flex-column text-start">
                                                                <div className="category">
                                                                    {i.category}
                                                                    <span className="ms-2">
                                                                        {
                                                                            i.container
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="flavour">
                                                                    {i.toppings!.map(
                                                                        (
                                                                            t,
                                                                            key
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                >
                                                                                    +{' '}
                                                                                    {
                                                                                        t
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-column align-items-end text-end second-col">
                                                                <div className="price">
                                                                    {i.price}{' '}
                                                                    <span className="currency-text">
                                                                        kr
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                return (
                                                    <div
                                                        key={key}
                                                        className="item py-2 d-flex justify-content-between"
                                                    >
                                                        <div className="d-flex flex-column text-start">
                                                            <div className="category">
                                                                {i.category}
                                                            </div>
                                                            <div className="flavour">
                                                                +{' '}
                                                                {i.guteSnurrFlavor ||
                                                                    i.milkshakeFlavor ||
                                                                    i.iceCreamMenuFlavor}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column align-items-end text-end second-col">
                                                            <div className="price">
                                                                {i.price}{' '}
                                                                <span className="currency-text">
                                                                    kr
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className="text-end total mb-3">
                                                Totalt:{' '}
                                                <span>{`${order?.total!}kr`}</span>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        <a href="/" className="btn btn-secondary">
                            Tillbaka till start
                        </a>
                    </>
                )}
            </div>
        </div>
    )
}
export default Receipt
