import { createContext, Context, PropsWithChildren, useState } from 'react'

interface IAdminContext {
    code: string
}

const AdminContext: Context<IAdminContext> = createContext<IAdminContext>({
    code: ''
})

export const AdminContextProvider = ({ children }: PropsWithChildren<any>) => {
    const [code] = useState('')

    // eslint-disable-next-line
    // const getBarCode = useCallback(async () => {
    //     setCode(await adminService.getBarCode())
    // }, [])

    // useEffect(() => {
    //     let urlPath = window.location.pathname
    //     if (urlPath.includes('guteordrar') || urlPath.includes('guteadmin')) {
    //         // getBarCode()
    //     }
    // })

    return (
        <AdminContext.Provider
            value={{
                code
            }}
        >
            {children}
        </AdminContext.Provider>
    )
}
export default AdminContext
