import { useState, useEffect, useRef } from 'react'
import { getToken } from '../../../services/adminService'

interface CustomerMessageProps {}

const CustomerMessage = () => {
    return (
        <>
            <FormInput />
        </>
    )
}

function FormInput() {
    const [customerMessage, setCustomerMessage] = useState<string>('')
    const [postStatus, setPostStatus] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [messageIsLoading, setMessageIsLoading] = useState<boolean>(true)
    const inputRef = useRef<HTMLInputElement>(null)
    async function postData(
        message: string,
        setIsLoading: (val: boolean) => void
    ) {
        let timeout: NodeJS.Timeout
        const url = new URL(
            process.env.REACT_APP_GUTEGLASSAPI_DOMAIN + '/Admin/message'
        )

        url.searchParams.append('message', message)
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'text/html; charset=utf-8',
                Authorization: `Bearer ${await getToken()}`
            }
        }

        try {
            const response = await fetch(url, requestOptions)
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }
            console.log('Success:', response)
            setIsLoading(false)
            setPostStatus('Sparat!')
            timeout = setTimeout(() => {
                setPostStatus('')
                clearTimeout(timeout)
            }, 5000)
        } catch (error) {
            console.error('Error:', error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()
        const signal = controller.signal
        async function fetchData(): Promise<void> {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_GUTEGLASSAPI_DOMAIN}/Order/message`
                )

                if (!response.ok) {
                    throw new Error(
                        'Network response was not ok while fetching message data'
                    )
                }

                const data = await response.text()

                setMessageIsLoading(false)
                if (isMounted) {
                    setCustomerMessage(data)
                }
                return
            } catch (error) {
                console.error(error)
            }
        }
        fetchData()

        return () => {
            // add this function
            controller.abort()
            isMounted = false
        }
    }, [])

    return (
        <>
            <div className="pb-3">
                <h4>Kundmeddelande</h4>
            </div>
            <div>
                <label className="pb-2" htmlFor="CustomerMessage">
                    <span>
                        Används för att sända ut ett viktigt kundmeddelade.
                    </span>
                </label>
                <p className="small">
                    För att sätta ett meddelande som kunden ej ska kunna trycka
                    bort, till exempel "Stängt för ikväll" fyll i en # i
                    meddelandet så visas ej någon knapp "Stängt för ikväll #"
                </p>

                <p className="small">
                    För att ta bort nuvarande meddelande, spara ett tomt
                    meddelande
                </p>

                {!messageIsLoading ? (
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text h-100">
                                Meddelande:
                            </span>
                        </div>
                        <input
                            ref={inputRef}
                            type="text"
                            name="CustomerMessage"
                            onInput={(e) => {
                                setCustomerMessage(
                                    inputRef.current?.value ?? ''
                                )
                            }}
                            className="text-start form-control"
                            defaultValue={customerMessage ?? ''}
                        />{' '}
                    </div>
                ) : (
                    <div
                        className="spinner-border text-success d-block"
                        role="status"
                    >
                        <span className="sr-only"></span>
                    </div>
                )}

                <div className="input-group pt-3 pb-2 d-flex align-items-center">
                    <button
                        onClick={() => {
                            setIsLoading(true)
                            postData(customerMessage, setIsLoading)
                        }}
                        type="button"
                        className="btn btn-success"
                    >
                        {isLoading ? (
                            <div
                                className="spinner-border text-light"
                                role="status"
                            >
                                <span className="sr-only"></span>
                            </div>
                        ) : (
                            'Spara'
                        )}
                    </button>
                </div>
                <div className="d-block">{postStatus && postStatus}</div>
            </div>
        </>
    )
}

export default CustomerMessage
