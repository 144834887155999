import { useCallback, useContext, useEffect, useRef, useState } from 'react' // eslint-disable-line
import { Cached } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import NewAdminItems from '../components/admin/NewAdminItems'
import AuthContext from '../contexts/AuthContext'
import { IOrder } from '../interfaces/i-order'
import adminService from '../services/adminService'
import AdminHeader from '../components/admin/AdminHeader'

function Admin() {
    const { logout } = useContext(AuthContext)
    const history = useHistory()
    const [error, setError] = useState<Error>()
    const [loading, setLoading] = useState({ refresh: false, logout: false })
    const [orders, setOrders] = useState<IOrder[]>([])
    const [audio] = useState(new Audio('/sound.mp3'))

    async function handleLogout() {
        setLoading({ ...loading, logout: true })
        setError(undefined)
        try {
            await logout()
            setLoading({ ...loading, logout: false })
            history.push('/gutelogin')
        } catch (e) {
            setLoading({ ...loading, logout: false })
            setError(new Error('Utloggningen misslyckades'))
        }
    }

    function sortOrders(ord: IOrder[]) {
        const sorted = [...ord].sort(
            (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
        )
        return sorted
    }

    const getOrders = useCallback(async () => {
        setLoading({ ...loading, refresh: true })
        try {
            let data = await adminService.getNewOrders()
            let sortedOrders = sortOrders(data)
            setOrders(sortedOrders)
            setError(undefined)
            setLoading({ ...loading, refresh: false })
        } catch (error: any) {
            setLoading({ ...loading, refresh: false })
            setError(
                new Error(
                    `Något gick fel vid hämtning av ordrar, försök igen. ${error}`
                )
            )
        }
    }, [])

    const ordersRef = useRef(orders)
    useEffect(() => {
        ordersRef.current = orders
    }, [orders])

    useEffect(() => {
        getOrders()
        const orderSync = setInterval(async () => {
            let data = await adminService.getNewOrders()
            let sortedOrders = sortOrders(data)
            if (sortedOrders.length > ordersRef.current.length) {
                await audio.play()
            }
            setOrders(sortedOrders)
        }, 30000)

        return () => {
            clearInterval(orderSync)
        }
    }, [])

    return (
        <div className="admin page py-3">
            <AdminHeader />
            <div className="container">
                <div
                    className="d-flex align-items-center header pt-5"
                    id="page-wrap"
                >
                    <div
                        onClick={() => getOrders()}
                        className="refresh d-flex justify-content-center align-items-center"
                    >
                        {loading.refresh ? (
                            <div className="loader"></div>
                        ) : (
                            <Cached style={{ fontSize: 40 }} />
                        )}
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 content-right">
                        <h2>Orderfeed</h2>
                    </div>
                </div>
                {error && <div>{error.message}</div>}
                <NewAdminItems orders={orders} setOrders={setOrders} />
                <div className="logout mt-5 pt-5">
                    <button
                        className="btn btn-outline-secondary"
                        onClick={handleLogout}
                    >
                        {loading.logout ? (
                            <div className="d-flex justify-content-center btn btn-primary mt-4">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <>Logga ut</>
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Admin
