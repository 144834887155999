import React, { useContext } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import AuthContext from '../contexts/AuthContext'

interface PrivateRouteProps extends RouteProps {
    children?: React.ReactNode
    component?: any
    path: string
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, path, ...rest } = props
    const { currentUser, loading } = useContext(AuthContext)
    sessionStorage.setItem('redirect', path)
    return (
        <>
            {loading ? (
                <div className="loader-outer d-flex justify-content-center align-items-center">
                    <div className="loader"></div>
                </div>
            ) : (
                <Route
                    {...rest}
                    render={(routeProps) => {
                        return currentUser ? (
                            <Component {...routeProps} />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: '/gutelogin',
                                    state: { from: routeProps.location }
                                }}
                            />
                        )
                    }}
                ></Route>
            )}
        </>
    )
}
export default PrivateRoute
