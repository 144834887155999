import { IOrder } from '../interfaces/i-order'

interface ICardDoneOrder {
    item: IOrder
    text: string
    icon?: string
    ready?: boolean
}
const CardDoneOrder = ({ item, text, icon, ready }: ICardDoneOrder) => {
    let name
    if (item.name !== undefined) {
        name =
            item.name.length > 10
                ? `${item.name.substring(0, 10)}...`
                : item.name
    }

    return (
        <div
            className="card-doneorder p-3"
            // style={{
            //     backgroundImage: `url('img/mjgl-kupong-default.svg')`,
            // }}
        >
            <div>
                {/* <div className='ordernr-text'>ORDER NR.</div> */}
                <div className="ordernr">{`#${item.orderNumber ?? ''}`}</div>
                <div className="name">{name ?? ''}</div>
            </div>
            <div className="bottom-content d-flex align-items-end justify-content-center">
                {icon && (
                    <img
                        src={icon}
                        alt="icon"
                        className={!ready ? 'spin' : ''}
                    />
                )}
                <div className="text-ready">{text}</div>
            </div>
        </div>
    )
}
export default CardDoneOrder
