import {
    PaymentRequestErrorCodes,
    PaymentResultErrorCodes
} from './SwishErrorCodes'

export interface IPaymentRequest {
    id: string
    payeePaymentReference: string
    paymentReference: string
    callbackUrl: string
    payerAlias: string
    payeeAlias: string
    currency: string
    amount: number
    message: string
    status: PaymentRequestStatus
    dateCreated: string
    datePaid: string
    errorCode: string
    errorMessage: string
}

export interface IPaymentResponse {
    id: string
    token: string
}

export enum PaymentRequestStatus {
    Paid = 'PAID',
    Declined = 'DECLINED',
    Error = 'ERROR',
    Cancelled = 'CANCELLED'
}

export interface ISwishError {
    errorCode: PaymentRequestErrorCodes | PaymentResultErrorCodes
    errorMessage: string
}
