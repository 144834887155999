interface StatisticsObjectProps {
    obj: object
}

const StatisticsObject: React.FC<StatisticsObjectProps> = ({ obj }) => {
    return (
        <ul className="py-4">
            {Object.entries(obj).map(([key, value]) => {
                return (
                    <li className="list-group-item" key={key}>
                        {key}: {value}
                    </li>
                )
            })}
        </ul>
    )
}

export default StatisticsObject
