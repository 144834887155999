interface IModal {
    text: string
    onClick: Function
    openModal: any
    setOpenModal: Function
}

const Modal = ({ text, onClick, setOpenModal, openModal }: IModal) => {
    return (
        <div className="modal-modal d-flex justify-content-center align-items-center">
            <div className="content d-flex flex-column justify-content-center align-items-center text-center p-5">
                <div>{text}</div>
                <button
                    onClick={() => {
                        onClick()
                        setOpenModal({
                            ...openModal,
                            cancel: false,
                            ready: false,
                            pickedUp: false
                        })
                    }}
                    className="w-100 bg-success btn btn-primary mt-3"
                >
                    JA
                </button>
                <button
                    onClick={() => {
                        setOpenModal({
                            ...openModal,
                            cancel: false,
                            ready: false,
                            pickedUp: false
                        })
                    }}
                    className="w-100 btn btn-outline-primary mt-3"
                >
                    Nej
                </button>
            </div>
        </div>
    )
}
export default Modal
