import { IIceCream } from '../interfaces/i-icecream'
import {
    Category,
    GuteSnurr,
    IceCreamMenu,
    Milkshake
} from '../interfaces/IceCreamTypes'

export interface ICategory {
    title: Category
    description: string
    icon: string
    fromPrice: string
    items: IIceCream[]
}

export const categoryItems: ICategory[] = [
    {
        title: Category.SoftIceCream,
        description: 'Vår egen mjukglass på äkta ekologiska råvaror',
        icon: 'img/sketch-mjukglass.svg',
        fromPrice: 'fr. 45',
        items: [
            {
                category: Category.SoftIceCream,
                title: 'Ekologisk Mjukglass',
                description:
                    'Vår egen mjukglass på äkta ekologiska råvaror. Serveras i våffla eller bägare med valfri topping',
                img: 'img/softicecream.jpg',
                price: 45
            }
        ]
    },
    {
        title: Category.GuteSnurr,
        description:
            'Vår egen ekologiska mjukglass mixad med härliga tillbehör',
        icon: 'img/sketch-gutesnurr.svg',
        fromPrice: 'fr. 55',
        items: [
            {
                category: Category.GuteSnurr,
                title: 'Polka',
                description:
                    'Ekologiska mjukglass med bitar av polkagris från Wisby kokeriet samt vår egen hallonsås',
                img: 'img/original-2BDDF37A-CB07-48F7-965D-FBA564D4CFCA.jpeg',
                flavor: GuteSnurr.Polka,
                price: 55
            },
            {
                category: Category.GuteSnurr,
                title: 'Kolaknäck',
                description:
                    'Ekologisk mjukglass mixad med kolasås från Börslycke gård och vårt eget knäckiga och salta strössel',
                img: 'img/image003.jpg',
                flavor: GuteSnurr.CaramelButterscotch,
                price: 55
            },
            {
                category: Category.GuteSnurr,
                title: 'Trippelchoklad',
                description:
                    'Ekologiska mjukglass med chokladkolasås från Börslycke gård samt bitar av ekologisk vit choklad och mjölkchoklad från Malmö chokladfabrik',
                img: 'img/trippelchoklad.jpeg',
                flavor: GuteSnurr.Trippelchoklad,
                price: 55
            },
            {
                category: Category.GuteSnurr,
                title: 'Lemoncurd & maräng',
                description:
                    'Ekologisk mjukglass med äkta engelsk lemoncurd och ekologiska maränger från hönseriet i Mjällby',
                img: 'img/image001.jpg',
                flavor: GuteSnurr.Lemon,
                price: 55
            },
            {
                category: Category.GuteSnurr,
                title: 'Gör din egen Gutesnurr',
                description:
                    'Välj fritt bland alla våra strössel och såser (max 4 tillbehör per bägare)',
                img: 'img/IMG_2145.jpg',
                flavor: GuteSnurr.Customized,
                price: 60
            }
        ]
    },
    {
        title: Category.Milkshake,
        description: 'Milkshake gjord på vår ekologiska mjukglass',
        icon: 'img/sketch-milkshake.svg',
        fromPrice: '75',
        items: [
            {
                category: Category.Milkshake,
                title: 'Milkshake CHOKLADBOMB',
                description:
                    'Vår egen ekologiska mjukglass blandat med Gute Glass ekologisk chokladglass, toppat med chokladsås, grädde och chokladströssel',
                img: 'img/image009.jpg',
                flavor: Milkshake.Chocolate,
                price: 75
            },
            {
                category: Category.Milkshake,
                title: 'Milkshake Cookies n´cream',
                description:
                    'Vår egen ekologiska mjukglass blandad med  mjölk, kaksmulor, toppat med grädde & cookies',
                img: 'img/image007.jpg',
                flavor: Milkshake.CookiesCream,
                price: 75
            },
            {
                category: Category.Milkshake,
                title: 'Milkshake Vanilj & citron',
                description:
                    'Vår egen ekologiska mjukglass blandad med mjölk & citron, engelsk lemoncurd toppad med maräng',
                img: 'img/image008.jpg',
                flavor: Milkshake.LemonVanilla,
                price: 75
            },
            {
                category: Category.Milkshake,
                title: 'Milkshake Jordgubb',
                description:
                    'Vår egen ekologiska mjukglass blandat med Gute Glass ekologisk jordgubbsglass, och bitar av gotländska jordgubbar och grädde på toppen',
                img: 'img/IMG_4813.jpg',
                flavor: Milkshake.Jordgubb,
                price: 75
            }
        ]
    },
    {
        title: Category.IceCreamMenu,
        description: 'Olika glassmenyer på vår ekologiska glass',
        icon: 'img/sketch-milkshake.svg',
        fromPrice: '80',
        items: [
            {
                category: Category.IceCreamMenu,
                title: 'Klassisk marängsviss',
                description:
                    'Ekologisk chokladglass med vår egen mjukglass, maränger, banan, chokladsås och grädde',
                img: 'img/IMG_4645.jpg',
                flavor: IceCreamMenu.Marangsviss,
                price: 80
            },
            {
                category: Category.IceCreamMenu,
                title: 'Bananhalve',
                description:
                    'Choklad- och jordgubbsglass toppad med banan, chokladsås och vår egen mjukglass',
                img: 'img/IMG_4648.jpg',
                flavor: IceCreamMenu.Bananhalve,
                price: 80
            },
            {
                category: Category.IceCreamMenu,
                title: 'Gotländsk sommar',
                description:
                    'Ekologisk honung och saffransglass med salmbärssylt och vår egen mjukglass',
                img: 'img/IMG_4834.jpg',
                flavor: IceCreamMenu.GotlandskSommar,
                price: 80
            },
            {
                category: Category.IceCreamMenu,
                title: 'Gute brickan',
                description:
                    'En avsmakningsmeny med Gute glass-favoriter: tre sorters kulglass och 2 sorters mjukglass',
                img: 'img/IMG_4829.jpg',
                flavor: IceCreamMenu.GuteBrickan,
                price: 100
            }
        ]
    }
]
