import { useContext, useEffect, useState } from 'react'
import { Category, GuteSnurr } from '../../interfaces/IceCreamTypes'
import OrderContext from '../../contexts/OrderContext'
import CardDoneOrder from '../CardDoneOrder'
import { IOrder } from '../../interfaces/i-order'
import adminService from '../../services/adminService'
import { auth } from '../../firebase'

const CompleteView = () => {
    const { setOrder, setSelectedItems, order } = useContext(OrderContext)
    const urlPath = window.location.pathname
    const [path, setPath] = useState('/')

    const [loading, setLoading] = useState(false) // eslint-disable-line
    const [error, setError] = useState<Error | undefined>() // eslint-disable-line
    const [total, setTotal] = useState<number>(0)

    const orderValues: IOrder = JSON.parse(localStorage.getItem('order')!)
    localStorage.setItem('items', JSON.parse('[]'))

    const submit = async () => {
        if (order === undefined) {
            try {
                let ord = await adminService.placeOrder(orderValues)
                setOrder(ord)
                setSelectedItems([])
                setTotal(
                    ord?.items
                        .filter((i) => i.price)
                        .reduce((sum, current) => sum + current.price, 0)
                )
                setLoading(false)
            } catch (e: any) {
                console.log(e)
                setError(new Error(e))
                setLoading(false)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        submit()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        let footer = document.getElementById('footer')
        if (footer) {
            footer.style.display = 'none'
            footer.style.visibility = 'hidden'
        }
    }, [])

    auth.onAuthStateChanged(function (user) {
        if (user && urlPath.includes('guteadminorder')) {
            setPath('guteadminorder')
        }
    })

    return (
        <div className="complete view pt-3">
            {loading ? (
                <div className="loader-outer d-flex justify-content-center align-items-center">
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    {order !== undefined && (
                        <>
                            <h1>Tack för din beställning!</h1>
                            <div className="my-2">
                                Vi kommer ut med din beställning när den är redo
                                och ropar upp ditt namn
                            </div>
                            <div className="mb-2">Ditt ordernummer är</div>
                            <CardDoneOrder
                                item={order!}
                                text={'Förbereds'}
                                icon="img/ikon-forbereds.svg"
                            />
                            <div className="chosen-items mt-3 mb-5">
                                <div className="order-heading mb-2">
                                    Din beställning
                                </div>
                                {order?.items?.map((i, key) => {
                                    if (
                                        i.category === Category.SoftIceCream ||
                                        (i.category === Category.GuteSnurr &&
                                            i.guteSnurrFlavor ===
                                                GuteSnurr.Customized)
                                    ) {
                                        return (
                                            <div
                                                key={key}
                                                className="item py-2 softicecream d-flex justify-content-between"
                                            >
                                                <div className="d-flex flex-column text-start">
                                                    <div className="category">
                                                        {i.category}
                                                        <span className="ms-2">
                                                            {i.container}
                                                        </span>
                                                    </div>
                                                    <div className="flavour">
                                                        {i.toppings!.map(
                                                            (t, key) => {
                                                                return (
                                                                    <div
                                                                        className="text-start"
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        + {t}
                                                                    </div>
                                                                )
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column align-items-end text-end second-col">
                                                    <div className="price">
                                                        {i.price}{' '}
                                                        <span className="currency-text">
                                                            kr
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    return (
                                        <div
                                            key={key}
                                            className="item py-2 d-flex justify-content-between"
                                        >
                                            <div className="d-flex flex-column text-start">
                                                <div className="category">
                                                    {i.category}
                                                </div>
                                                <div className="flavour">
                                                    +{' '}
                                                    {i.guteSnurrFlavor ||
                                                        i.milkshakeFlavor ||
                                                        i.iceCreamMenuFlavor}
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column align-items-end text-end second-col">
                                                <div className="price">
                                                    {i.price}{' '}
                                                    <span className="currency-text">
                                                        kr
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="text-end total mb-3">
                                    Totalt:{' '}
                                    <span>{`${total!.toString()}kr`}</span>
                                </div>
                            </div>
                        </>
                    )}
                    {error && (
                        <div className="error-msg p-2">{error.message}</div>
                    )}
                    <a href={path} className="btn btn-secondary">
                        Tillbaka till start
                    </a>
                </>
            )}
        </div>
    )
}

export default CompleteView
