import { useContext } from 'react'
import AdminMenu from '../../components/Menu'
import AuthContext from '../../contexts/AuthContext'
import { NavLink } from 'react-router-dom'

const AdminHeader = () => {
    const logo = '/img/GG_Logo_2019_GG.svg'

    return (
        <header className="container-xxl admin-header-outer d-flex w-100 bg-white p-0 admin-header">
            <nav className="navbar navbar-expand-lg navbar-light p-0 w-100">
                <div className="col-12 d-flex justify-content-between">
                    <NavLink
                        className="d-flex align-items-center"
                        to="/guteadmin"
                    >
                        <img className="me-2 p-3" src={logo} alt="" />
                    </NavLink>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <AdminMenu />
                    </div>
                    <div className="d-flex align-items-center pe-3">
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
            </nav>
        </header>
    )
}

function UserStatus() {
    const { currentUser } = useContext(AuthContext)
    return (
        <div className="admin-header__user-status">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
            >
                <path d="M480-492.309q-57.749 0-98.874-41.124-41.125-41.125-41.125-98.874 0-57.75 41.125-98.874 41.125-41.125 98.874-41.125 57.749 0 98.874 41.125 41.125 41.124 41.125 98.874 0 57.749-41.125 98.874-41.125 41.124-98.874 41.124ZM180.001-187.694v-88.922q0-29.384 15.962-54.422 15.961-25.038 42.653-38.5 59.308-29.077 119.654-43.615T480-427.691q61.384 0 121.73 14.538 60.346 14.538 119.654 43.615 26.692 13.462 42.653 38.5 15.962 25.038 15.962 54.422v88.922H180.001ZM240-247.693h480v-28.923q0-12.154-7.039-22.5-7.038-10.346-19.115-16.885-51.692-25.461-105.418-38.577Q534.702-367.693 480-367.693t-108.428 13.115q-53.726 13.116-105.418 38.577-12.077 6.539-19.115 16.885Q240-288.77 240-276.616v28.923Zm240-304.614q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0-80Zm0 384.614Z" />
            </svg>
            {currentUser.email}
        </div>
    )
}

export default AdminHeader
