import 'bootstrap/dist/css/bootstrap.css'
import './App.scss'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import StartPage from './pages/StartPage'
import Admin from './pages/Admin'
import Login from './pages/Login'
import ControlPanel from './pages/ControlPanel'
import Receipt from './pages/Receipt'
import DoneOrders from './pages/DoneOrders'
import AdminOrder from './pages/AdminOrder'
import PrivateRoute from './components/PrivateRoute'
import { OrderContextProvider } from './contexts/OrderContext'
import { AdminContextProvider } from './contexts/AdminContext'
import { ErrorBoundary } from 'react-error-boundary'
import { AuthProvider } from './contexts/AuthContext'
import { useEffect, useState } from 'react'
import LogRocket from 'logrocket'

function App() {
    const [backroundImage, setBackroundImage] = useState('')
    useEffect(() => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('Development no logging')
            //LogRocket.init('ekhiub/guteshopui')
            //LogRocket.init('3x5ued/gg') // Viks logrocket
        } else {
            LogRocket.init('ekhiub/guteshopui')
            //LogRocket.init('3x5ued/gg') // Viks logrocket
        }
    }, [])

    function ErrorFallback({ error, resetErrorBoundary }: any) {
        return (
            <div className="error-fallback pt-4" role="alert">
                <p>Something went wrong:</p>
                <div className="px-3 pb-3" style={{ color: 'red' }}>
                    {error.message}
                </div>
                <button
                    style={{ color: '#282c34' }}
                    className="btn btn-secondary"
                    onClick={resetErrorBoundary}
                >
                    Try again
                </button>
            </div>
        )
    }

    useEffect(() => {
        let path = window.location.pathname
        if (path.includes('gutelogin')) {
            setBackroundImage(`url('img/mörk-canvas-812x2000-mobres.jpg')`)
        }
        if (path.includes('guteadmin')) {
            setBackroundImage('')
        }
        if (path.includes('guteordrar')) {
            setBackroundImage(`url('img/mörk-texture-1920x1080.jpg')`)
        }
    }, [setBackroundImage])

    return (
        <div
            className="App"
            style={{
                backgroundImage: backroundImage
            }}
        >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Router>
                    <AuthProvider>
                        <AdminContextProvider>
                            <OrderContextProvider>
                                <Switch>
                                    <PrivateRoute
                                        component={DoneOrders}
                                        path="/guteordrar"
                                    />

                                    <PrivateRoute
                                        component={Admin}
                                        path="/guteadmin"
                                    />

                                    <PrivateRoute
                                        component={AdminOrder}
                                        path="/guteadminorder"
                                    />
                                    <PrivateRoute
                                        component={ControlPanel}
                                        path="/gutekontrollpanel"
                                    />

                                    <Route
                                        path="/gutelogin"
                                        component={Login}
                                    />

                                    <Route
                                        exact
                                        path="/receipt"
                                        component={Receipt}
                                    />

                                    <Route
                                        exact
                                        path="/"
                                        component={StartPage}
                                    />
                                </Switch>
                            </OrderContextProvider>
                        </AdminContextProvider>
                    </AuthProvider>
                </Router>
            </ErrorBoundary>
        </div>
    )
}

export default App
