import { useContext, useState, useEffect } from 'react'
import { componentItems } from '../constants/componentItems'
import OrderContext from '../contexts/OrderContext'
import { ICategory } from '../constants/categoryItems'
import { CSSTransition } from 'react-transition-group'

interface ICategoryItem {
    item: ICategory
    onClick(): void
}

const CategoryItem = ({ item }: ICategoryItem) => {
    const { changeComponent, setChosenCategory } = useContext(OrderContext)
    const [show, setShow] = useState(false)
    function getCategory(c: ICategory) {
        setChosenCategory(c)
        changeComponent(componentItems[2])
    }

    useEffect(() => {
        setShow((prevShow) => !prevShow)
    }, [])

    return (
        <CSSTransition in={show} timeout={300} classNames="slide" unmountOnExit>
            <div
                onClick={() => getCategory(item)}
                className="px-3 row-cols-2 row overview-category--item pe-0"
            >
                <div className="col-10 overview-category--item-text-content">
                    <h2 className="position-relative">
                        {item.title}
                        {item.title === 'Glassmeny' ? (
                            <span className="position-absolute badge badge-secondary">
                                Nyhet!
                            </span>
                        ) : (
                            ''
                        )}
                    </h2>
                    <div className="desc">{item.description}</div>
                </div>
                <div className="col-2 item-cat-left-col pt-2 pe-0 d-flex align-items-center">
                    <span className="circle"></span>
                </div>
            </div>
        </CSSTransition>
    )
}

export default CategoryItem
