export enum PaymentRequestErrorCodes {
    FF08 = 'FF08', //PayeePaymentReference is invalid
    RP03 = 'RP03', //Callback URL is missing or does not use HTTPS
    BE18 = 'BE18', //Payer alias is invalid
    RP01 = 'RP01', //Payee alias is missing or empty
    PA02 = 'PA02', //Amount value is missing or not a valid number
    AM06 = 'AM06', //Amount value is too low
    AM02 = 'AM02', //Amount value is too large
    AM03 = 'AM03', //Invalid or missing Currency
    RP02 = 'RP02', //Wrong formatted message
    RP06 = 'RP06', //Another active PaymentRequest already exists for this payerAlias. (Only applicable for E-Commerce.)
    RP09 = 'RP09', //The given instructionUUID is not available Note: The instructionUUID already exist in the database, i.e. it is not unique.
    ACMT03 = 'ACMT03', //Payer not Enrolled
    ACMT01 = 'ACMT01', //Counterpart is not activated
    ACMT07 = 'ACMT07', //Payee not Enrolled
    UNKW = 'UNKW', //Technical supplier is not active (only applicable for Technical Supplier API user).
    VR01 = 'VR01', //Does not meet age limit. Note: Only E-Commerce case, for M-Commerce case the simulated error will trigger in the delayed ‘Payment result callback’ (second response).
    VR02 = 'VR02' //SSN does not match enroled customer. Note: Only E-Commerce case, for M-Commerce case the simulated error will trigger in the delayed ‘Payment result callback’ (second response).
}
export enum PaymentResultErrorCodes {
    RF07 = 'RF07', //Transaction declined
    BANKIDCL = 'BANKIDCL', //Payer cancelled BankId signing
    FF10 = 'FF10', //Bank system processing error
    TM01 = 'TM01', //Swish timed out before the payment was started
    DS24 = 'DS24', //Swish timed out waiting for an answer from the banks after payment was started. Note: If this happens Swish has no knowledge of whether the payment was successful or not. The Merchant should inform its consumer about this and recommend them to check with their bank about the status of this payment.
    VR01 = 'VR01', //Does not meet age limit. Note: Only M-Commerce case, for E-Commerce case the simulated error will trigger in the ‘Payment request created’ step (first response).
    VR02 = 'VR02' //SSN does not match enroled customer. Note: Only M-Commerce case, for E-Commerce case the simulated error will trigger in the ‘Payment request created’ step (first response).
}
