import { useContext, useState } from 'react'
import { componentItems } from '../../constants/componentItems'
import OrderContext from '../../contexts/OrderContext'
import { Close } from '@material-ui/icons'

interface IStartView {
    intro: JSX.Element
}

const StartView = ({ intro }: IStartView) => {
    const { changeComponent } = useContext(OrderContext)
    const [showMenu, setShowMenu] = useState(false)
    const [showAllergy, setShowAllergy] = useState(false)
    const [showInfo, setShowInfo] = useState(false)

    return (
        <div
            className="start view pt-3"
            id="start"
            onClick={() => {
                if (showAllergy) setShowAllergy(false)
                if (showMenu) setShowMenu(false)
            }}
        >
            <div className="mb-3 welcome">Välkommen till</div>
            {intro}
            <div
                className="primary d-flex justify-content-center align-items-center"
                onClick={() => changeComponent(componentItems[1])}
            >
                <span className="material-symbols-outlined">
                    fiber_manual_record
                </span>
                Påbörja beställning{' '}
                <span className="material-symbols-outlined">
                    fiber_manual_record
                </span>
            </div>
            <div className="secondary" onClick={() => setShowMenu(true)}>
                Tjuvkika på menyn
            </div>
            {showMenu && (
                <div className="modal-modal menu">
                    <Close
                        className="close-menu"
                        onClick={() => setShowAllergy(false)}
                        style={{ fontSize: 25 }}
                    />
                    <img src="img/Artboard2.png" alt="Meny" />
                    <img src="img/Artboard1.png" alt="Meny" />
                </div>
            )}
            <div className="secondary" onClick={() => setShowAllergy(true)}>
                Allergi
            </div>
            {showAllergy && (
                <div className="modal-modal allergy p-3">
                    <div className="d-flex justify-content-between">
                        <h3>Allergi</h3>
                        <Close
                            onClick={() => setShowAllergy(false)}
                            style={{ fontSize: 25 }}
                        />
                    </div>
                    <div className="">
                        Samtliga produkter innehåller ägg och mjölk
                    </div>
                    <div className="pt-3">
                        Samtliga Gutesnurr innehåller eller kan innehålla spår
                        av hasselnötter
                    </div>
                    <div className="pt-3">
                        Samtliga Milkshakes innehåller eller kan innehålla spår
                        av gluten
                    </div>
                    <div className="pt-3">
                        Samtliga Topping innehåller eller kan innehålla spår av
                        hasselnötter, gluten och mjölk
                    </div>
                </div>
            )}
            <a
                href="https://www.guteglass.se/kontakt"
                target="_blank"
                rel="noreferrer"
                className=""
            >
                <div className="secondary">Kontakta oss</div>
            </a>
            <div className="secondary" onClick={() => setShowInfo(!showInfo)}>
                Köpvillkor
            </div>
            {showInfo && (
                <div className="modal-modal menu p-3">
                    <div className="d-flex justify-content-between">
                        <div>Gotlandsglass AB</div>
                        <Close
                            onClick={() => setShowInfo(false)}
                            style={{ fontSize: 25 }}
                        />
                    </div>

                    <div>Org.nr: 556802-7857</div>
                    <div>BARLINGBO STAVA 300</div>
                    <div>621 78 Visby</div>
                    <div>info@guteglass.se</div>
                    <div className="mt-4">
                        <div>
                            Via denna app kan du beställa din mjukglass på vår
                            glassbar i Visby.
                        </div>
                        <div>
                            Du finner våra priser under respektive glass och kan
                            betala med Swish i sista steget.
                        </div>
                        <div>Betalningen sker i svenska kronor.</div>
                        <div className="mt-4">
                            Efter beställningen är lagd kommer du att kunna se
                            när glassen finns tillgänglig att hämta på vår skärm
                            i baren.
                        </div>
                    </div>
                    <div className="mt-4">
                        <b>Köp- och leveransvillkor</b>
                        <div>
                            Varan betalas i förskott och levereras och
                            konsumeras direkt på plats, ingen ångerrätt
                            tillämpas. Alla priser är inklusive 12 % moms.
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default StartView
