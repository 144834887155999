import { NavLink } from 'react-router-dom'

interface ControlPanelMenuProps {}

const ControlPanelMenu: React.FunctionComponent<ControlPanelMenuProps> = () => {
    return (
        <>
            <nav className="control-panel--menu">
                <ul className="list-group">
                    <li className="list-group-item p-0">
                        <NavLink
                            className="d-block w-100 h-100 p-2"
                            activeClassName="menu-active"
                            to={'/gutekontrollpanel/meddelande'}
                        >
                            Kundmeddelande
                        </NavLink>
                    </li>
                    <li className="list-group-item p-0">
                        <NavLink
                            className="d-block w-100 h-100 p-2"
                            activeClassName="menu-active"
                            to={'/gutekontrollpanel/statistik'}
                        >
                            Statistik
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default ControlPanelMenu
