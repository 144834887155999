import { useContext, useEffect, useState, useRef } from 'react'
import OrderContext from '../../contexts/OrderContext'
import { Add } from '@material-ui/icons'
import { Category, GuteSnurr } from '../../interfaces/IceCreamTypes'
import CustomizableSoftIceCream from '../CustomizableOverlay/CustomizableSoftIceCream'
import CustomizableGutesnurr from '../CustomizableOverlay/CustomizableGutesnurr'
import { CSSTransition } from 'react-transition-group'
import QuickMenu from '../QuickMenu'

interface ICartMessage {
    showModal: boolean
    modalRef: React.RefObject<HTMLDivElement>
}

function AddedToCartModal({ showModal, modalRef }: ICartMessage) {
    const phrases = [
        'Spikat!',
        'Bullseye!',
        'Snygg dragning!',
        'Klockrent!',
        'Bingo!',
        'Full pott!',
        'Träff mitt i prick!',
        'Kanondrag!',
        'Hundraprocentigt!',
        'Guldval!'
    ]

    function getRandomPhrase() {
        const randomIndex = Math.floor(Math.random() * phrases.length)
        return phrases[randomIndex]
    }

    return (
        <CSSTransition
            in={showModal}
            timeout={300}
            classNames="slide"
            unmountOnExit
        >
            <div ref={modalRef} className="added-to-cart-msg">
                <h2>{getRandomPhrase()}</h2>
            </div>
        </CSSTransition>
    )
}

const OrderView = () => {
    const [show, setShow] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const modalRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        setShow((prevShow) => !prevShow)
    }, [])

    const { selectedItems, chosenCategory, setSelectedItems, setTransition } =
        useContext(OrderContext)

    async function onClick(item: any) {
        await setShowModal(true)
        if (modalRef.current !== null) {
            modalRef.current.classList.add('message-modal-enter-active')

            setTimeout(() => {
                if (modalRef.current !== null) {
                    modalRef.current.classList.remove(
                        'message-modal-enter-active'
                    )
                }
            }, 1000)
        }

        if (item.category === Category.GuteSnurr) {
            setSelectedItems([
                ...selectedItems!,
                {
                    category: item.category,
                    price: item.price,
                    guteSnurrFlavor: item.flavor
                }
            ])
        }
        if (item.category === Category.Milkshake) {
            setSelectedItems([
                ...selectedItems!,
                {
                    category: item.category,
                    price: item.price,
                    milkshakeFlavor: item.flavor
                }
            ])
        }
        if (item.category === Category.IceCreamMenu) {
            setSelectedItems([
                ...selectedItems!,
                {
                    category: item.category,
                    price: item.price,
                    iceCreamMenuFlavor: item.flavor
                }
            ])
        }
        setTransition(true)
    }

    return (
        <>
            <QuickMenu />
            <CSSTransition
                in={show}
                timeout={300}
                classNames="slide"
                unmountOnExit
            >
                <div className="order-view view pb-5" id="order">
                    {chosenCategory?.items.map((item: any, key: number) => {
                        if (item.category === Category.SoftIceCream) {
                            return (
                                <CustomizableSoftIceCream
                                    key={key}
                                    item={item}
                                />
                            )
                        }
                        if (
                            item.category === Category.GuteSnurr &&
                            item.flavor === GuteSnurr.Customized
                        ) {
                            return (
                                <CustomizableGutesnurr key={key} item={item} />
                            )
                        }
                        return (
                            <div
                                key={key}
                                className={`icecream-item ${item.category}`}
                                onClick={() => onClick(item)}
                            >
                                <div
                                    className={`img-container position-relative ${
                                        item.noImg ? 'coming-soon' : ''
                                    }`}
                                    style={{
                                        backgroundImage: `url('${item.img}')`
                                    }}
                                >
                                    {' '}
                                    <div className="price">{`${item.price}`}</div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center info-container p-3">
                                    <div className="title-desc">
                                        <h3>{item.title}</h3>
                                        <div>{item.description}</div>
                                    </div>
                                    <Add style={{ fontSize: 35 }} />
                                </div>
                            </div>
                        )
                    })}
                    <AddedToCartModal
                        modalRef={modalRef}
                        showModal={showModal}
                    />
                </div>
            </CSSTransition>
        </>
    )
}

export default OrderView
