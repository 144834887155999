interface IAdminButton {
    className: string
    text: string
    modal: JSX.Element
    openModal: any
    openModalCancel?: string
    openModalReady?: string
    openModalPickedUp?: string
    setOpenModal: Function
}

const AdminButton = ({
    className,
    text,
    modal,
    openModal,
    openModalCancel,
    openModalReady,
    openModalPickedUp,
    setOpenModal,
}: IAdminButton) => {
    function openModalSwitch() {
        if (openModalCancel) {
            setOpenModal({ ...openModal, cancel: true })
            return
        }
        if (openModalReady) {
            setOpenModal({ ...openModal, ready: true })
            return
        }
        if (openModalPickedUp) {
            setOpenModal({ ...openModal, pickedUp: true })
            return
        }
    }

    function open() {
        if (openModalCancel) {
            return openModal.cancel
        }
        if (openModalReady) {
            return openModal.ready
        }
        if (openModalPickedUp) {
            return openModal.pickedUp
        }
    }

    return (
        <>
            <button onClick={() => openModalSwitch()} className={className}>
                {text}
            </button>
            {open() && <>{modal}</>}
        </>
    )
}
export default AdminButton
