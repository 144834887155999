import { NavLink } from 'react-router-dom'

interface IMenu {
    user: any
}

interface IMenuItem {
    to: string
    text: string
}

function Menu() {
    const menuItems: IMenuItem[] = [
        {
            to: '/guteadmin',
            text: 'Orderfeed'
        },
        // {
        //     to: '/guteordrar',
        //     text: 'Färdiga ordrar "Skärmen"'
        // },
        {
            to: '/guteadminorder',
            text: 'Beställningssida personal'
        },
        {
            to: '/gutekontrollpanel',
            text: 'Kontrollpanel'
        }
    ]
    return (
        <div className="admin-menu">
            <ul className="px-0 m-0">
                {menuItems.map((item, index) => (
                    <li className="py-2 text-center" key={index}>
                        <NavLink
                            className="py-2"
                            activeClassName="menu-active"
                            to={item.to}
                        >
                            {item.text}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Menu
