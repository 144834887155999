interface IHR {
    text?: string
}
const HR = ({ text }: IHR) => {
    return (
        <div className="text-center d-flex">
            {text ? (
                <>
                    <hr />
                    {text}
                    <hr />
                </>
            ) : (
                <hr />
            )}
        </div>
    )
}
export default HR
