import { useContext } from 'react'
import { Close } from '@material-ui/icons'
import { Category, GuteSnurr } from '../interfaces/IceCreamTypes'
import OrderContext from '../contexts/OrderContext'

const SelectedItems = () => {
    const { selectedItems, setSelectedItems, setTransition } =
        useContext(OrderContext)

    function removeItem(item: any) {
        setSelectedItems(selectedItems.filter((i) => i !== item))
        setTransition(true)
    }
    return (
        <>
            {selectedItems?.map((i, key) => {
                if (
                    i.category === Category.SoftIceCream ||
                    (i.category === Category.GuteSnurr &&
                        i.guteSnurrFlavor === GuteSnurr.Customized)
                ) {
                    return (
                        <div
                            key={key}
                            className="item py-2 softicecream d-flex justify-content-between"
                        >
                            <div className="d-flex flex-column text-start">
                                <div className="category">
                                    {i.category}
                                    <span className="ms-2">{i.container}</span>
                                </div>
                                <div className="flavour text-start">
                                    {i.toppings!.map((t, key) => {
                                        return <span key={key}>- {t}</span>
                                    })}
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-end text-end second-col">
                                <Close
                                    onClick={() => removeItem(i)}
                                    style={{ fontSize: 25, color: 'Red' }}
                                />
                                <div className="price">
                                    {i.price}{' '}
                                    <span className="currency-text">kr</span>
                                </div>
                            </div>
                        </div>
                    )
                }
                return (
                    <div
                        key={key}
                        className="item py-2 d-flex justify-content-between"
                    >
                        <div className="d-flex flex-column text-start">
                            <div className="category">{i.category}</div>
                            <div className="flavour">
                                -{' '}
                                {i.guteSnurrFlavor ||
                                    i.milkshakeFlavor ||
                                    i.iceCreamMenuFlavor}
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-end text-end second-col">
                            <Close
                                onClick={() => removeItem(i)}
                                style={{ fontSize: 25, color: 'Red' }}
                            />
                            <div className="price">
                                {i.price}{' '}
                                <span className="currency-text">kr</span>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default SelectedItems
