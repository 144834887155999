export enum Category {
    SoftIceCream = 'Mjukglass',
    GuteSnurr = 'Gutesnurr',
    Milkshake = 'Milkshake',
    IceCreamMenu = 'Glassmeny'
}

export enum GuteSnurr {
    CaramelButterscotch = 'Kolaknäck',
    Trippelchoklad = 'Trippelchoklad',
    Lemon = 'Lemoncurd & Maräng',
    Polka = 'Polka',
    Customized = 'Customized'
}

export enum Milkshake {
    Chocolate = 'Choklad',
    LemonVanilla = 'Citron & Vanilj',
    CookiesCream = 'Cookies n´cream',
    Jordgubb = 'Jordgubb'
}

export enum IceCreamMenu {
    Marangsviss = 'Klassisk marängsviss',
    Bananhalve = 'Bananhalve',
    GotlandskSommar = 'Gotländsk sommar',
    GuteBrickan = 'Gute brickan'
}

export enum Topping {
    //Sprikles
    RaspberrySprinkles = 'Hallon strössel',
    ChocolateSprinkles = 'Choklad strössel',
    CandiedHazelnuts = 'Kanderade hasselnötter',
    SaltedCaramelCookies = 'Salta knäckkakor',
    RockyCookies = 'Rockykakor',
    SaltLicoriceFlakes = 'Salt Lakritsflakes',
    PeppermintCandyCane = 'Polkagris',
    FruitSprinkles = 'Fruktströssel',
    Cinnamon = 'Kanelbulle',
    RoastedCoconut = 'Rostad kokos',
    //Sauce
    Salmberry = 'Salmbärssylt',
    ChocolateSauce = 'Chokladsås',
    CaramelSauce = 'Kolasås',
    Lemoncurd = 'Lemoncurd',
    RaspberrySauce = 'Hallonsås',
    StrawberrySauce = 'Jordgubbssås',
    ChocolateDip = 'Chokladdopp',
    NoSprinkles = 'Ingen topping'
}

export enum Container {
    Waffle = 'Våffla',
    Cup = 'Bägare'
}
