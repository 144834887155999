import { IOrder } from '../interfaces/i-order'
import appConfig from '../appConfig'
import { auth } from '../firebase'
const axios = require('axios').default

export async function getToken() {
    let accessToken: any = null
    await auth.currentUser?.getIdToken().then((token: string) => {
        accessToken = token
    })
    localStorage.setItem('accessToken', accessToken)
    return accessToken
}

const adminService = {
    async getNewOrders() {
        const response = await axios
            .create({
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await getToken()}`
                }
            })
            .get(`${appConfig.SERVER_URL}/admin/getneworders`)
            .then((result: any) => {
                //console.log(result.data)
                return result.data as IOrder[]
            })
            .catch((e: any) => {
                console.error(e, 33)
                if (e.response) {
                    if (e.response.status === 404) {
                        throw new Error('Not found')
                    }
                    throw e
                }
                throw e
            })
        return response as IOrder[]
    },
    async placeOrder(order: IOrder) {
        const response = await axios
            .create({
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await getToken()}`
                }
            })
            .post(`${appConfig.SERVER_URL}/admin/order`, order)
            .then((result: any) => {
                // console.log(result.data)
                return result.data as IOrder
            })
            .catch((e: any) => {
                console.error(e, 58)
                if (e.response) {
                    if (e.response.status === 404) {
                        throw new Error('Not found')
                    }
                    throw new Error(
                        `Något gick fel, vänligen försök igen. ${e}`
                    )
                }
                throw new Error(`Något gick fel, vänligen försök igen. ${e}`)
            })
        return response as IOrder
    },
    async updateorder(order: IOrder) {
        const response = await axios
            .create({
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await getToken()}`
                }
            })
            .put(`${appConfig.SERVER_URL}/order/updateorder`, order)
            .then((result: any) => {
                // console.log(result.data)
                return result.data as IOrder[]
            })
            .catch((e: any) => {
                console.error(e, 83)
                if (e.response) {
                    if (e.response.status === 404) {
                        throw new Error('Not found')
                    }
                    throw e
                }
                throw e
            })
        return response as IOrder[]
    },
    async getOrdersReady() {
        const response = await axios
            .create({
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await getToken()}`
                }
            })
            .get(`${appConfig.SERVER_URL}/admin/getordersready`)
            .then((result: any) => {
                console.log(result.data)
                return result.data as IOrder[]
            })
            .catch((e: any) => {
                console.error(e, 133)
                if (e.response) {
                    if (e.response.status === 404) {
                        throw new Error('Not found')
                    }
                    throw e
                }
                throw e
            })
        return response as IOrder[]
    }
}
export default adminService
