import { IOrder } from '../interfaces/i-order'
import appConfig from '../appConfig'

const axios = require('axios').default
let instance = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

const orderService = {
    async authenticateWithBarcode(code: number) {
        const response = await instance
            .get(`${appConfig.SERVER_URL}/order/authenticate?code=${code}`)
            .then((result: any) => {
                console.log(result.data)
                return result.data
            })
            .catch((e: any) => {
                console.error(e, 21)
                if (e.response) {
                    if (e.response.status === 404) {
                        throw new Error('Felaktig barkod, försök igen')
                    }
                    if (e.response.status === 400) {
                        throw new Error('Något gick fel, vänligen försök igen.')
                    }
                }
                throw new Error(`Något gick fel, vänligen försök igen. ${e}`)
            })
        return response
    },
    async getOrder(id: string) {
        const response = await instance
            .get(`${appConfig.SERVER_URL}/order/order?id=${id}`)
            .then((result: any) => {
                return result.data as IOrder
            })
            .catch((e: any) => {
                console.error(e, 41)
                if (e.response) {
                    if (e.response.status === 404) {
                        console.error(e.response)
                        throw new Error('Order kunde ej hittas')
                    }
                    throw new Error(
                        `Något gick fel, vänligen försök igen. ${e}`
                    )
                }
                throw new Error(`Något gick fel, vänligen försök igen. ${e}`)
            })
        return response as IOrder
    },
    async updateOrder(order: IOrder) {
        const response = await instance
            .put(`${appConfig.SERVER_URL}/order/updateorder`, order)
            .then((result: any) => {
                console.log(result.data)
                return result.data as IOrder
            })
            .catch((e: any) => {
                console.error(e, 63)
                if (e.response) {
                    if (e.response.status === 404) {
                        throw new Error('Not found')
                    }
                    throw new Error(
                        `Något gick fel, vänligen försök igen. ${e}`
                    )
                }
                throw new Error(`Något gick fel, vänligen försök igen. ${e}`)
            })
        return response as IOrder
    }
}
export default orderService
