import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { useEffect, useState } from 'react'
import { Category, GuteSnurr } from '../../interfaces/IceCreamTypes'
import { IOrder } from '../../interfaces/i-order'
import AdminButton from './AdminButton'
import Modal from '../Modal'

const Timer = (props: any) => {
    const {
        initialMinute = 0,
        initialSeconds = 0,
        setStyleNewOrder = () => {}
    } = props
    const [minutes, setMinutes] = useState(initialMinute)
    const [seconds, setSeconds] = useState(initialSeconds)

    useEffect(() => {
        if (minutes <= 1) {
            setStyleNewOrder({
                // borderBottom: '3px solid #7dca7d',
                color: '#7dca7d'
                // backgroundColor: '#7dca7d'
            })
        }
        if (minutes >= 1) {
            setStyleNewOrder({
                // borderBottom: '3px solid #ffc107',
                color: '#fd7e14'
                // backgroundColor: '#cecece'
            })
        }
    }, [minutes, setStyleNewOrder])

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds < 59) {
                setSeconds(seconds + 1)
            }
            if (seconds === 59) {
                setMinutes(minutes + 1)
                setSeconds(0)
            }
        }, 1000)
        return () => {
            clearInterval(myInterval)
        }
    })

    return (
        <>
            {minutes < 59 ? (
                <>
                    {' '}
                    {minutes === 0 && seconds === 0 ? null : (
                        <div style={props.style}>
                            {' '}
                            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                        </div>
                    )}
                </>
            ) : (
                <>1h+</>
            )}
        </>
    )
}

interface IAdminOrderItem {
    item: IOrder
    setItemToReady: Function
    setItemToPickedUp: Function
    cancelOrder: Function
}
const AdminOrderItem = ({
    item,
    setItemToReady,
    setItemToPickedUp,
    cancelOrder
}: IAdminOrderItem) => {
    const [openModal, setOpenModal] = useState({
        cancel: false,
        ready: false,
        pickedUp: false
    })

    const [styleNewOrder, setStyleNewOrder] = useState({})
    const [expand, setExpand] = useState(false)
    const [error, setError] = useState<Error>()
    let diff = Math.abs((new Date() as any) - (new Date(item.time!) as any))
    let minutes = Math.floor(diff / 1000 / 60)
    let seconds = Math.floor(minutes / 60)

    return (
        <>
            {!item.isPickedUp && (
                <div className="admin-item">
                    <div
                        className="d-flex align-items-center head"
                        onClick={() => setExpand(!expand)}
                    >
                        <div className="time">
                            <Timer
                                style={styleNewOrder}
                                setStyleNewOrder={setStyleNewOrder}
                                initialMinute={minutes}
                                initialSeconds={seconds}
                            />
                        </div>
                        <div className="me-auto name">{item.name}</div>
                        <div className="order-number">{`#${item.orderNumber}`}</div>
                        <div className="expand">
                            {expand ? (
                                <ExpandLess style={{ fontSize: 40 }} />
                            ) : (
                                <ExpandMore style={{ fontSize: 40 }} />
                            )}
                        </div>
                    </div>
                    {expand && (
                        <div className="expanded-container d-flex flex-column">
                            {item.items.map((icecream, key) => {
                                if (
                                    icecream.category === Category.SoftIceCream
                                ) {
                                    return (
                                        <div
                                            key={key}
                                            className="d-flex flex-column icecream-item p-4"
                                        >
                                            <div className="icecream-item--inner">
                                                <div className="d-flex">
                                                    <h5>
                                                        {icecream.category}
                                                        <span>
                                                            {icecream.container}
                                                        </span>
                                                    </h5>
                                                </div>
                                                <div className="d-flex">
                                                    {icecream.toppings!.map(
                                                        (i, key) => {
                                                            return (
                                                                <div
                                                                    key={key}
                                                                    className="flavour"
                                                                >
                                                                    {i}
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                if (
                                    icecream.category === Category.GuteSnurr &&
                                    icecream.guteSnurrFlavor ===
                                        GuteSnurr.Customized
                                ) {
                                    return (
                                        <div
                                            key={key}
                                            className="d-flex flex-column icecream-item p-4"
                                        >
                                            <div className="icecream-item--inner">
                                                <h5>
                                                    {icecream.category}
                                                    <span>
                                                        {
                                                            icecream.guteSnurrFlavor
                                                        }
                                                    </span>
                                                </h5>
                                                <div className="">
                                                    {icecream.toppings!.map(
                                                        (i, key) => {
                                                            return (
                                                                <div
                                                                    key={key}
                                                                    className="flavour"
                                                                >
                                                                    {i}
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                return (
                                    <div
                                        key={key}
                                        className="d-flex flex-column icecream-item p-4"
                                    >
                                        <div className="icecream-item--inner">
                                            <h5>{icecream.category}</h5>
                                            <div className="d-flex">
                                                {icecream.guteSnurrFlavor && (
                                                    <div className="flavour">
                                                        {
                                                            icecream.guteSnurrFlavor
                                                        }
                                                    </div>
                                                )}
                                                {icecream.milkshakeFlavor && (
                                                    <div className="flavour">
                                                        {
                                                            icecream.milkshakeFlavor
                                                        }
                                                    </div>
                                                )}
                                                {icecream.iceCreamMenuFlavor && (
                                                    <div className="flavour">
                                                        {
                                                            icecream.iceCreamMenuFlavor
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {error && <div>{error.message}</div>}
                            <div className="d-flex justify-content-between flex-row-reverse mt-3">
                                {!item.isReady && (
                                    <AdminButton
                                        className="text-white btn btn-outline-primary bg-danger px-4"
                                        text="Avbryt"
                                        modal={
                                            <Modal
                                                text="Är du säker på att du vill avbryta order?"
                                                onClick={() =>
                                                    cancelOrder(item, setError)
                                                }
                                                openModal={openModal}
                                                setOpenModal={setOpenModal}
                                            />
                                        }
                                        openModal={openModal}
                                        openModalCancel={'cancel'}
                                        setOpenModal={setOpenModal}
                                    />
                                )}
                                {/* {!item.isReady && (
                                    <AdminButton
                                        className="btn btn-secondary px-5"
                                        text={`${item.orderNumber}# Redo för pickup`}
                                        modal={
                                            <Modal
                                                text="Är du säker på att du vill sätta order som redo?"
                                                onClick={() =>
                                                    setItemToReady(
                                                        item,
                                                        setError
                                                    )
                                                }
                                                openModal={openModal}
                                                setOpenModal={setOpenModal}
                                            />
                                        }
                                        openModal={openModal}
                                        openModalReady={'ready'}
                                        setOpenModal={setOpenModal}
                                    />
                                )} */}
                                <AdminButton
                                    className="btn bg-success btn-primary px-5"
                                    text="Upphämtad"
                                    modal={
                                        <Modal
                                            text="Är du säker på att du vill sätta order som upphämtad?"
                                            onClick={() =>
                                                setItemToPickedUp(
                                                    item,
                                                    setError
                                                )
                                            }
                                            openModal={openModal}
                                            setOpenModal={setOpenModal}
                                        />
                                    }
                                    openModal={openModal}
                                    openModalPickedUp={'pickedUp'}
                                    setOpenModal={setOpenModal}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
export default AdminOrderItem
