import React, {
    Context,
    PropsWithChildren,
    useContext,
    useEffect,
    useState
} from 'react'
import {
    User,
    getAuth,
    signInWithEmailAndPassword,
    signOut
} from 'firebase/auth'

interface IAuthContext {
    login: Function
    logout: Function
    currentUser: any
    loading: boolean
    loginError: Error | undefined
    loggedIn: boolean
}

const AuthContext: Context<IAuthContext> = React.createContext<IAuthContext>({
    login: () => {},
    logout: () => {},
    currentUser: '',
    loading: true,
    loginError: undefined,
    loggedIn: false
})

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }: PropsWithChildren<any>) {
    const [currentUser, setCurrentUser] = useState<User | undefined>()
    const [loading, setLoading] = useState(true)
    const [loginError, setLoginError] = useState<Error | undefined>()
    const [loggedIn, setLoggedIn] = useState(false)

    async function login(email: string, password: string) {
        const auth = getAuth()
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user
                setCurrentUser(user)
                setLoggedIn(true)
                // ...
            })
            .catch((error) => {
                const errorCode = error.code
                const errorMessage = error.message
                if (errorCode === 'auth/wrong-password') {
                    setLoginError(new Error('Felaktigt lösenord, försök igen'))
                } else if (errorCode === 'auth/too-many-requests') {
                    setLoginError(
                        new Error(
                            'Åtkomst till detta konto har tillfälligt inaktiverats på grund av många misslyckade inloggningsförsök. Du kan omedelbart återställa det genom att återställa ditt lösenord eller så kan du försöka igen senare.'
                        )
                    )
                } else {
                    setLoginError(new Error(errorMessage))
                }
                console.log(errorCode)
                console.log(errorMessage)
            })
    }

    async function logout() {
        const auth = getAuth()
        signOut(auth)
            .then(() => {
                setLoggedIn(false)
                setCurrentUser(undefined)
                // Sign-out successful.
            })
            .catch((error) => {
                // An error happened.
            })
        // return firebase.auth().signOut()
    }

    useEffect(() => {
        const auth = getAuth()
        const unsubscribe = auth.onAuthStateChanged((user: any) => {
            if (user) {
                setCurrentUser(user)
                setLoggedIn(true)
            }
            setLoading(false)
        })
        return unsubscribe
    }, [currentUser])

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                login,
                logout,
                loading,
                loginError,
                loggedIn
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
export default AuthContext
