import { useContext, useEffect, useState } from 'react'
import { componentItems } from '../constants/componentItems'
import OrderContext from '../contexts/OrderContext'
import { IOrder } from '../interfaces/i-order'
import { IPaymentResponse } from '../interfaces/i-payment'
import swishService from '../services/swishService'
import { isAndroid, isIOS } from 'react-device-detect'
import appConfig from '../appConfig'

interface ICheckoutForm {
    setShowForm: Function
}

const CheckoutForm = ({ setShowForm }: ICheckoutForm) => {
    const { selectedItems, changeComponent, isLoggedIn } =
        useContext(OrderContext)
    const [requiredMessage, setRequiredMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | undefined>()
    const [name, setName] = useState('')

    const [deepLink, setDeepLink] = useState<string | undefined>()

    const [focused, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    useEffect(() => {
        if (focused) {
            let footer = document.getElementById('footer')
            if (footer) {
                footer.style.display = 'none'
                footer.style.visibility = 'hidden'
            }
        }
        if (!focused) {
            let footer = document.getElementById('footer')
            if (footer) {
                footer.style.display = 'block'
                footer.style.visibility = 'visible'
            }
        }
    }, [focused])

    const completeOrder = () => {
        localStorage.setItem(
            'order',
            JSON.stringify({
                name: name,
                items: selectedItems!,
                isReady: false,
                isPickedUp: false,
                isCanceled: false,
                time: new Date(Date.now())
            })
        )
        changeComponent(componentItems[4])
    }

    const createPaymentRequest = async (e: any) => {
        e.preventDefault()
        setLoading(true)
        if (name === '') {
            setRequiredMessage('Fyll i ditt namn')
            setLoading(false)
            return
        }
        setRequiredMessage('')
        let orderValues: IOrder = {
            name: name,
            items: selectedItems!,
            isReady: false,
            isPickedUp: false,
            isCanceled: false,
            time: new Date(Date.now()),
            isPaid: false
        }
        localStorage.setItem(
            'order',
            JSON.stringify({
                name: '',
                items: selectedItems!,
                isReady: false,
                isPickedUp: false,
                isCanceled: false,
                time: new Date(Date.now()),
                isPaid: false
            })
        )
        try {
            const paymentRequest: IPaymentResponse =
                await swishService.createPaymentRequest(orderValues)

            const callbackURL = `${appConfig.CALLBACK_URL}receipt%3Fref%3D${paymentRequest.id}`
            //const appUrl = `swish://paymentrequest?token=${paymentRequest.token}&callbackurl=${callbackURL}`
            //window.location.href = appUrl

            if (isAndroid) {
                const url = `swish://paymentrequest?token=${paymentRequest.token}&callbackurl=${callbackURL}/#Intent;scheme=swish;package=se.bankgirot.swish;end`
                window.location.replace(url)

                setTimeout(() => {
                    setDeepLink(
                        `swish://paymentrequest?token=${paymentRequest.token}&callbackurl=${callbackURL}/#Intent;scheme=swish;package=se.bankgirot.swish;end`
                    )
                }, 4000)
            } else if (isIOS) {
                window.location.replace(
                    `swish://paymentrequest?token=${paymentRequest.token}&callbackurl=${callbackURL}`
                )

                setTimeout(() => {
                    setDeepLink(
                        `swish://paymentrequest?token=${paymentRequest.token}&callbackurl=${callbackURL}`
                    )
                }, 4000)
            }

            setLoading(false)
        } catch (e: any) {
            setError(new Error(e))
            setLoading(false)
            console.error(e)
        }
    }

    const inputHandler = (e: any) => {
        setName(e.target.value)
    }

    return (
        <div className="checkout-form d-flex justify-content-center align-items-center">
            {loading ? (
                <div className="loader-outer d-flex justify-content-center align-items-center">
                    <div className="loader"></div>
                </div>
            ) : (
                <div className="container d-flex flex-column justify-content-center align-items-center">
                    <h1>Nästan klar!</h1>
                    <h4 className="px-3">
                        Skriv in ditt namn i fältet nedan och fortsätt genom att
                        trycka på swish-knappen
                    </h4>
                    <form className="name-form bg-white d-flex flex-column align-items-center">
                        <input
                            type="text"
                            autoComplete="off"
                            name="name"
                            className="form-control"
                            placeholder="Skriv ditt namn..."
                            maxLength={20}
                            onChange={inputHandler}
                            onFocus={onFocus}
                            onBlur={onBlur}
                        />
                        <div className="req-msg text-start">
                            {requiredMessage}
                        </div>

                        {isLoggedIn ? (
                            <button
                                className="btn bg-success btn-primary mt-3"
                                onClick={() => completeOrder()}
                            >
                                Betala
                            </button>
                        ) : (
                            <button
                                onClick={(e) => createPaymentRequest(e)}
                                className="btn btn-outline-primary mt-3"
                            >
                                Fortsätt med{' '}
                                <img
                                    src="img/Swish_Logo_Secondary_CMYK.svg"
                                    alt="Swish logo"
                                    className="swish-logo"
                                />
                            </button>
                        )}

                        <button
                            onClick={() => setShowForm(false)}
                            className="close-payment-btn btn btn-outline-primary mt-3"
                        >
                            Avbryt
                        </button>
                    </form>
                    {deepLink !== undefined && (
                        <div className="swish-link">
                            <div>
                                Om du inte har blivit omdirigerad automatiskt,
                                klicka på följande länk, om det fortfarande inte
                                händer något, vänligen beställ inne i kassan.
                            </div>
                            <a href={deepLink}>Öppna Swish</a>
                        </div>
                    )}
                    {error && (
                        <div className="error-msg p-3">{error.message}</div>
                    )}
                </div>
            )}
        </div>
    )
}

export default CheckoutForm
