import { useContext, useState } from 'react'
import { componentItems } from '../../constants/componentItems'
import OrderContext from '../../contexts/OrderContext'
import CheckoutForm from '../CheckoutForm'
import SelectedItems from '../SelectedItems'

const CheckoutView = () => {
    const { selectedItems, changeComponent } = useContext(OrderContext)

    const [showForm, setShowForm] = useState(false)
    const [noItems, setNoItems] = useState('')

    let total = selectedItems
        .filter((i) => i.price)
        .reduce((sum, current) => sum + current.price, 0)

    const completeOrder = () => {
        if (selectedItems.length === 0) {
            setNoItems('Du har ingenting i varukorgen än!')
            return
        }
        setShowForm(true)
    }

    return (
        <div className="checkout view d-flex flex-column">
            <div className="order-heading mt-4">Din varukorg</div>

            <div className="chosen-items my-2">
                <div className="req-msg">{noItems}</div>
                <SelectedItems />
            </div>
            <div className="text-end total mb-3">
                Totalt: <span>{`${total.toString()}:-`}</span>
            </div>
            <div className="mt-4">
                Kontrollera att allt stämmer och <b>betala med swish</b> i nästa
                steg.
            </div>
            <div className="d-flex mt-5 gap-2">
                <button
                    className="btn btn-secondary px-1 me-2"
                    onClick={() => changeComponent(componentItems[1])}
                >
                    Gå tillbaka
                </button>
                <button
                    className="btn btn-primary px-2"
                    onClick={() => completeOrder()}
                >
                    Betala
                </button>
            </div>
            {showForm && <CheckoutForm setShowForm={setShowForm} />}
        </div>
    )
}

export default CheckoutView
