import { Suspense } from 'react'

interface ContentBoxProps {
    children: React.ReactNode
}

function ContentBox({ children }: ContentBoxProps) {
    return (
        <Suspense fallback={'Loading'}>
            <div className="content-box py-5 px-4">{children}</div>
        </Suspense>
    )
}

export default ContentBox
