import {
    IPaymentRequest,
    IPaymentResponse,
    ISwishError
} from '../interfaces/i-payment'
import appConfig from '../appConfig'
import {
    PaymentRequestErrorCodes,
    PaymentResultErrorCodes
} from '../interfaces/SwishErrorCodes'
import { IOrder } from '../interfaces/i-order'

const axios = require('axios').default
let instance = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

const swishService = {
    async createPaymentRequest(order: IOrder): Promise<IPaymentResponse> {
        console.log('Create paymentrequest')
        const response = await instance
            .put(`${appConfig.SERVER_URL}/swish/createpaymentrequest`, order)
            .then((result: any) => {
                console.log(result)
                return result.data as IPaymentResponse
            })
            .catch((e: any) => {
                if (e.response) {
                    if (e.response.status === 422) {
                        console.error(e, 34)
                        let error = e.response.data.error as ISwishError
                        throw new Error(
                            `${error.errorCode} ${getErrorMessage(
                                error.errorCode
                            )}`
                        )
                    }
                    if (e.response.status === 400) {
                        console.error(e, 43)
                        throw new Error('400 Bad Request')
                    }
                    if (e.response.status === 401) {
                        console.error(e, 47)
                        throw new Error('401 Unauthorized')
                    }
                    if (e.response.status === 403) {
                        console.error(e, 51)
                        throw new Error('403 Forbidden')
                    }
                    console.error(e, 54)
                    throw new Error(
                        `Något gick fel, om du inte har blivit omdirigerad automatiskt vänligen beställ inne i kassan.`
                    )
                }
                console.log('Trying ping')
                instance
                    .get(`${appConfig.SERVER_URL}/swish/ping`)
                    .then((result: any) => {
                        console.log(result)
                        console.log(result.data)
                    })
                    .catch((e: any) => {
                        console.error('Ping error: ' + e)
                    })
                console.error(e)
                throw new Error(
                    `Vissa av våra kunder upplever problem med swish betalningar. Om du är en av dom är du välkommen in att beställa i kassan. Du kan även kontrollera din internetanslutning och försökta igen.`
                )
            })
        return response as IPaymentResponse
    },
    async getPaymentRequest(id: string) {
        const response = await instance
            .get(`${appConfig.SERVER_URL}/swish/getpaymentrequest?id=${id}`)
            .then((result: any) => {
                console.log(result)
                console.log(result.data)
                return result.data as IPaymentRequest
            })
            .catch((e: any) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        // Hämta från swish dirr
                        try {
                            console.log('GetPaymentRequestFromSwish')
                            const res =
                                swishService.getPaymentRequestFromSwish(id)
                            return res
                        } catch (e) {
                            console.error(e, 94)
                            throw new Error(
                                `Betalningen hittades ej, prova att ladda om sidan och om ordern inte uppdateras kontakta personalen.`
                            )
                        }
                    }
                    console.error(e, 101)
                    throw new Error(
                        // Här, knapp för att ladda om sidan?
                        `Något gick fel, vänligen försök igen eller kontakta personalen.`
                    )
                }
                console.error(e, 107)
                // Här, knapp för att ladda om sidan?
                // testa ladda om sidan en gång annars skriv ut kontakta personalen??
                throw new Error(
                    `Något gick fel, vänligen försök igen eller kontakta personalen.`
                )
            })
        // om errormsg och errorCode exist getErrorMessage
        // eller kanske här?
        return response as IPaymentRequest
    },
    async getPaymentRequestFromSwish(id: string) {
        const response = await instance
            .get(
                `${appConfig.SERVER_URL}/swish/getpaymentrequestswish?id=${id}`
            )
            .then((result: any) => {
                console.log(result.data)
                return result.data as IPaymentRequest
            })
            .catch((e: any) => {
                console.error(e, 128)
                if (e.response) {
                    console.error(e.response)
                    if (e.response.status === 404) {
                        throw new Error(
                            `Betalningen hittades ej, prova att ladda om sidan och om ordern inte uppdateras kontakta personalen.`
                        )
                    }
                    throw new Error(
                        `Något gick fel, vänligen försök igen eller kontakta personalen.`
                    )
                }
                throw new Error(
                    `Något gick fel, vänligen försök igen eller kontakta personalen.`
                )
            })
        return response as IPaymentRequest
    }
}
export default swishService

export function getErrorMessage(
    code: PaymentRequestErrorCodes | PaymentResultErrorCodes
) {
    switch (code) {
        case PaymentRequestErrorCodes.FF08:
            return 'Betalningsreferens är ogiltig'
        case PaymentRequestErrorCodes.RP03:
            return 'Callback URL saknas eller använder inte HTTPS'
        case PaymentRequestErrorCodes.BE18:
            return 'Betalarens alias är ogiltigt'
        case PaymentRequestErrorCodes.RP01:
            return 'Betalningsmottagarens alias saknas eller är tomt'
        case PaymentRequestErrorCodes.PA02:
            return 'Beloppsvärdet saknas eller är inte ett giltigt tal'
        case PaymentRequestErrorCodes.AM06:
            return 'Beloppsvärdet är för lågt'
        case PaymentRequestErrorCodes.AM02:
            return 'Beloppsvärdet är för stort'
        case PaymentRequestErrorCodes.AM03:
            return 'Ogiltig eller saknad valuta'
        case PaymentRequestErrorCodes.RP02:
            return 'Felformaterat meddelande'
        case PaymentRequestErrorCodes.RP06:
            return 'En annan aktiv betalningsbegäran finns redan för detta payer alias'
        case PaymentRequestErrorCodes.RP09:
            return 'Det givna IDt är inte tillgängligt'
        case PaymentRequestErrorCodes.ACMT03:
            return 'Betalare är inte registrerad'
        case PaymentRequestErrorCodes.ACMT01:
            return 'Motsvarigheten är inte aktiverad'
        case PaymentRequestErrorCodes.ACMT07:
            return 'Betalningsmottagaren är inte registrerad'
        case PaymentRequestErrorCodes.UNKW:
            return 'Teknisk leverantör är inte aktiv'
        case PaymentRequestErrorCodes.VR01:
            return 'Uppfyller inte åldersgränsen'
        case PaymentRequestErrorCodes.VR02:
            return 'Personnummer matchar inte registrerad kund'

        // här skriv också ut att det är i final state, så gå till kassan eller gå tillbaka och försök igen
        case PaymentResultErrorCodes.RF07:
            return 'Överföring nekad'
        case PaymentResultErrorCodes.BANKIDCL:
            return 'Betalaren avbröt BankId-signeringen'
        case PaymentResultErrorCodes.FF10:
            return 'Bearbetningsfel i banksystemet'
        case PaymentResultErrorCodes.TM01:
            return 'Swish tog timeout innan betalningen påbörjades'
        case PaymentResultErrorCodes.DS24:
            return 'Swish tog timeout i väntan på svar från bankerna efter att betalning påbörjats. Vi kan tyvärr inte avgöra om betalningen lyckades och rekomenderar dig att kontrollera betalningen i din internetbank. Gå till kassan så hjälper vi dig.'
        case PaymentResultErrorCodes.VR01:
            return 'Uppfyller inte åldersgränsen'
        case PaymentResultErrorCodes.VR02:
            return 'Personnummer matchar inte registrerad kund'
    }
}
