import { IIceCream } from '../../interfaces/i-icecream'
import { Close } from '@material-ui/icons'
import { useCallback, useContext, useEffect, useState } from 'react'
import OrderContext from '../../contexts/OrderContext'
import { Category, GuteSnurr, Topping } from '../../interfaces/IceCreamTypes'
import { ToppingItem } from '../ToppingItem'

interface ICustomizableOverlayGutesnurr {
    item: IIceCream
    overlayEl: React.RefObject<HTMLDivElement>
    setOpenToppings: React.Dispatch<React.SetStateAction<boolean>>
    setTotalTopping: React.Dispatch<React.SetStateAction<number>>
    setToppingErrorMsg: React.Dispatch<React.SetStateAction<string>>
    setTotalContainer: React.Dispatch<React.SetStateAction<number>>
    total: number
    totalTopping: number
}
const CustomizableOverlayGutesnurr = ({
    item,
    overlayEl,
    setOpenToppings,
    setTotalTopping,
    setToppingErrorMsg,
    total,
    totalTopping
}: ICustomizableOverlayGutesnurr) => {
    const { selectedItems, setSelectedItems, setTransition } =
        useContext(OrderContext)

    const [errorMsg, setErrorMsg] = useState('')

    const [checkedToppings, setCheckedToppings] = useState(
        Object.values(Topping).map((value) => {
            return {
                active: '',
                checked: false,
                topping: value
            }
        })
    )

    const [toppingClick, setToppingClick] = useState<number>(0)
    const [topping, setTopping] = useState<Topping[]>([])

    const [noToppingChecked, setNoToppingChecked] = useState(true)

    function isSprinkle(topping: Topping) {
        switch (topping) {
            case Topping.RaspberrySprinkles:
            case Topping.ChocolateSprinkles:
            case Topping.CandiedHazelnuts:
            case Topping.SaltedCaramelCookies:
            case Topping.RockyCookies:
            case Topping.SaltLicoriceFlakes:
            case Topping.PeppermintCandyCane:
            case Topping.FruitSprinkles:
            case Topping.Cinnamon:
            case Topping.RoastedCoconut:
                return true
            case Topping.Salmberry:
            case Topping.ChocolateSauce:
            case Topping.CaramelSauce:
            case Topping.Lemoncurd:
            case Topping.RaspberrySauce:
            case Topping.StrawberrySauce:
                return false
        }
    }

    const onChangeTopping = useCallback(
        (e: any, checkedTopping: any) => {
            let newToppingArr = topping
            let newCheckedToppingArr = checkedToppings
            if (e.target.checked) {
                if (newToppingArr.length > 0) {
                    setToppingClick(toppingClick + 1)
                    if (toppingClick === 3) {
                        setToppingErrorMsg('Du kan max välja fyra tillbehör')
                    }
                }
                if (topping.length <= 3) {
                    setNoToppingChecked(false)
                    let newArr = newCheckedToppingArr.map((i) => {
                        if (i === checkedTopping) {
                            i.checked = true
                            i.active = 'active'
                        }
                        return i
                    })
                    setCheckedToppings(newArr)
                    let newTopp = newToppingArr.filter(
                        (i) => i !== Topping.NoSprinkles
                    )
                    setTopping([...newTopp, checkedTopping.topping])
                }
            } else {
                let newArr = newCheckedToppingArr.map((i) => {
                    if (i === checkedTopping) {
                        i.checked = false
                        i.active = ''
                    }
                    return i
                })
                setCheckedToppings(newArr)
                setTopping(
                    newToppingArr.filter((i) => i !== checkedTopping.topping)
                )
            }
        }, //eslint-disable-next-line
        [topping, checkedToppings, toppingClick, totalTopping]
    )

    function addIceCream() {
        if (item.category === Category.GuteSnurr && topping.length === 0) {
            setErrorMsg('Vänligen välj tillbehör')
            return
        }
        setSelectedItems([
            ...selectedItems!,
            {
                guteSnurrFlavor: GuteSnurr.Customized,
                toppings: topping,
                category: Category.GuteSnurr,
                price: total
            }
        ])
        // }
        setOpenToppings(false)
        if (overlayEl !== null) {
            overlayEl.current?.setAttribute('aria-hidden', 'true')
        }
        document.body.classList.remove('noscroll')
        setTransition(true)
    }

    async function closeOverlay() {
        await setOpenToppings(false)
        if (overlayEl !== null) {
            overlayEl.current?.setAttribute('aria-hidden', 'true')
        }
        document.body.classList.remove('noscroll')
    }

    return (
        <div
            ref={overlayEl}
            className="choise-two p-3"
            aria-hidden="true"
            id="choise-two"
        >
            <div className="topping-container d-flex flex-column justify-content-center">
                <div className="position-relative">
                    <Close
                        onClick={() => closeOverlay()}
                        style={{ fontSize: 25 }}
                    />
                    <div className="d-flex justify-content-center mb-3">
                        <hr />
                        Välj tillbehör
                        <hr />
                    </div>
                </div>
                {item.category === Category.SoftIceCream && (
                    <label>
                        <div className={`d-flex justify-content-between`}>
                            <div className="topping">Ingen topping</div>
                            <div>
                                0kr
                                <input
                                    checked={noToppingChecked}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setNoToppingChecked(true)
                                            let newArr = checkedToppings.map(
                                                (i) => {
                                                    i.checked = false
                                                    i.active = ''
                                                    return i
                                                }
                                            )
                                            setCheckedToppings(newArr)
                                            setTopping([Topping.NoSprinkles])
                                            setTotalTopping(0)
                                            return
                                        }
                                        setTopping([Topping.NoSprinkles])
                                        setNoToppingChecked(false)
                                    }}
                                    type="checkbox"
                                />
                            </div>
                        </div>
                    </label>
                )}
                <div className="position-relative d-flex flex-column justify-content-center">
                    <div className="text-center d-flex">
                        <hr />
                        Strössel
                        <hr />
                    </div>
                    {/* eslint-disable-next-line */}
                    {checkedToppings.map((i, key) => {
                        if (isSprinkle(i.topping))
                            return (
                                <ToppingItem
                                    key={key}
                                    item={i}
                                    price={
                                        item.category === Category.SoftIceCream
                                            ? '+10kr'
                                            : ''
                                    }
                                    onChange={onChangeTopping}
                                    name={i.topping}
                                />
                            )
                    })}
                    <div className="text-center d-flex">
                        <hr />
                        Såser
                        <hr />
                    </div>
                    {checkedToppings.map((i, key) => {
                        if (!isSprinkle(i.topping))
                            return (
                                <>
                                    {i.topping === Topping.ChocolateDip ||
                                    i.topping === Topping.NoSprinkles ? (
                                        <></>
                                    ) : (
                                        <ToppingItem
                                            key={key}
                                            item={i}
                                            price={
                                                item.category ===
                                                Category.SoftIceCream
                                                    ? '+10kr'
                                                    : ''
                                            }
                                            onChange={onChangeTopping}
                                            name={i.topping}
                                        />
                                    )}
                                </>
                            )
                    })}
                </div>
                <div className="text-center total mb-3">
                    Totalt: <span>{`${total?.toString()}kr`}</span>
                </div>
                {errorMsg && (
                    <div className="container-error my-1">{errorMsg}</div>
                )}
                <div className="d-flex justify-content-between">
                    <button
                        onClick={() => closeOverlay()}
                        className="btn btn-outline-primary"
                    >
                        Gå tillbaka
                    </button>
                    <button
                        onClick={() => addIceCream()}
                        className="btn btn-primary bg-success ms-auto"
                    >
                        Lägg i varukorgen
                    </button>
                </div>
            </div>
        </div>
    )
}
export default CustomizableOverlayGutesnurr
