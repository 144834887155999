interface IOverlayButtons {
    total: number
    errorMsg: string
    closeOverlay: Function
    addIceCream: Function
}
const OverlayButtons = ({
    total,
    errorMsg,
    closeOverlay,
    addIceCream
}: IOverlayButtons) => {
    return (
        <>
            <div className="text-center total mb-3">
                Totalt: <span>{`${total?.toString()}kr`}</span>
            </div>
            {errorMsg && <div className="container-error my-1">{errorMsg}</div>}
            <div className="d-flex justify-content-between">
                <button
                    onClick={() => closeOverlay()}
                    className="btn btn-outline-primary"
                >
                    Gå tillbaka
                </button>
                <button
                    onClick={() => addIceCream()}
                    className="btn btn-primary ms-auto bg-success"
                >
                    Lägg i varukorgen
                </button>
            </div>
        </>
    )
}
export default OverlayButtons
