import { useContext, useState, useEffect } from 'react'
import Footer from '../components/Footer'
import OrderContext from '../contexts/OrderContext'
import Loader from '../components/Loader'
import { CSSTransition } from 'react-transition-group'
import { Popup } from '../components/customerMessage/CustomerMessage'
function StartPage() {
    const { component } = useContext(OrderContext)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [show, setShow] = useState(false)
    useEffect(() => {
        // setIsLoading(true)
        const timer = setTimeout(() => {
            // setIsLoading(false)
        }, 1000) // change this delay to suit your needs

        setShow(true)
        // cleanup function
        return () => clearTimeout(timer)
    }, [component])

    return (
        <CSSTransition in={show} timeout={300} classNames="slide" unmountOnExit>
            <div className="startpage-outer">
                {component.header}
                <Popup />
                <div
                    className="startpage container"
                    style={{ backgroundColor: component.backgroundColor }}
                >
                    {isLoading ? (
                        <Loader />
                    ) : (
                        component.show && component.component
                    )}
                </div>

                <Footer />
            </div>
        </CSSTransition>
    )
}

export default StartPage
