import CategoryItem from '../CategoryItem'
import { useEffect, useState } from 'react'
import { categoryItems } from '../../constants/categoryItems'
import { CSSTransition } from 'react-transition-group'

const CategoryView = () => {
    const [show, setShow] = useState(false)
    useEffect(() => {
        setShow((prevShow) => !prevShow)
    }, [])

    return (
        <CSSTransition in={show} timeout={300} classNames="slide" unmountOnExit>
            <div className="category-view view" id="category">
                {categoryItems.map((i, key) => {
                    return (
                        <CategoryItem
                            onClick={() => setShow((prevShow) => !prevShow)}
                            key={key}
                            item={i}
                        />
                    )
                })}
            </div>
        </CSSTransition>
    )
}

export default CategoryView
