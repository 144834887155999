import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AuthContext from '../contexts/AuthContext'

function Login() {
    let history = useHistory()
    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | undefined>()
    const backroundImage = `url('img/mörk-canvas-812x2000-mobres.jpg')`

    const { login, loginError, currentUser, loggedIn } = useContext(AuthContext)

    useEffect(() => {
        const redirect = sessionStorage.getItem('redirect')
        if (currentUser) {
            if (redirect) {
                history.push(redirect)
            } else {
                history.push('/guteadmin')
            }
        }
        //eslint-disable-next-line
    }, [loggedIn, history])

    async function handleLogin() {
        setLoading(true)
        try {
            await login(username.trim(), password.trim())
        } catch (e) {
            setError(new Error(`Inloggningen misslyckades ${e}`))
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <div
            className="login page px-3 pt-3"
            style={{
                backgroundImage: backroundImage
            }}
        >
            <div className="container d-flex flex-column align-items-center">
                <img
                    src="img/gutelogo-halv-vit.svg"
                    alt="logo"
                    className="logo"
                />
                <h2 className="my-5">Personalinloggning</h2>
                <form className="d-flex flex-column">
                    <div>
                        <label>Användarnamn</label>
                        <input
                            type="text"
                            className="form-control mb-3"
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Lösenord</label>
                        <input
                            type="password"
                            className="form-control mb-3"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    {loginError && (
                        <div className="my-2">{loginError.message}</div>
                    )}
                    {error && <div className="my-2">{error.message}</div>}
                    {loading ? (
                        <div className="d-flex justify-content-center btn btn-outline-primary mt-4">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        <input
                            type="submit"
                            className="btn btn-outline-primary mt-4"
                            value="Logga in"
                            onClick={() => handleLogin()}
                        />
                    )}
                </form>
            </div>
        </div>
    )
}

export default Login
