import { useContext } from 'react'
import Footer from '../components/Footer'
import OrderContext from '../contexts/OrderContext'
import AdminHeader from '../components/admin/AdminHeader'

function AdminOrder() {
    const { component } = useContext(OrderContext)

    return (
        <div className="startpage-outer">
            <AdminHeader />
            {component.headerAdmin}
            <div
                className="startpage container"
                style={{ backgroundColor: component.backgroundColor }}
            >
                {component.show &&
                    (component.componentAdmin ?? component.component)}
            </div>
            <Footer />
        </div>
    )
}

export default AdminOrder
