import { useCallback, useContext, useEffect, useState } from 'react'
import { IOrder } from '../interfaces/i-order'
import adminService from '../services/adminService'
import CardDoneOrder from '../components/CardDoneOrder'
import { useHistory } from 'react-router-dom'
import AuthContext from '../contexts/AuthContext'

function DoneOrders() {
    // const { logout } = useContext(AuthContext)
    // const history = useHistory()
    const [orders, setOrders] = useState<IOrder[]>([])

    // async function handleLogout() {
    //     try {
    //         await logout()
    //         history.push('/gutelogin')
    //     } catch (e) {}
    // }

    const getOrders = useCallback(async () => {
        let data = await adminService.getOrdersReady()
        let sortedOrders = sortOrders(data)
        setOrders(sortedOrders)
    }, [])

    useEffect(() => {
        getOrders()
        //eslint-disable-next-line
    }, [])

    function sortOrders(ord: IOrder[]) {
        const sorted = ord.sort(
            (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
        )
        const slicedArr = sorted.slice(0, 5)
        return slicedArr
    }

    useEffect(() => {
        const orderInterval = setInterval(async () => {
            await getOrders()
        }, 30000)

        return () => {
            clearInterval(orderInterval)
        }
    }, [getOrders])

    return (
        <div className="done-orders page container-fluid p-0">
            <div className="d-flex justify-content-center align-items-start px-3 header mb-4">
                {/* <img
                    src='img/GG_Logo_2019_GG.svg'
                    alt='logo'
                    className='logo'
                    onClick={handleLogout}
                /> */}
                <div
                    // onClick={handleLogout}
                    className="barkod-skylt"
                    // style={{
                    //     backgroundImage: `url('img/barkod-skylt.svg')`,
                    // }}
                >
                    <h4>Beställningar redo att hämtas</h4>
                    {/* <h4>Dagens barkod</h4> */}
                    {/* <h1>{code}</h1> */}
                </div>
                {/* <div style={{ width: '230px' }}></div> */}
            </div>
            <div className="py-5 d-flex">
                {orders.map((i, key) => {
                    return (
                        <CardDoneOrder
                            key={key}
                            item={i}
                            text={'Klar att hämta'}
                            // icon='img/ikon-klar.svg'
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default DoneOrders
