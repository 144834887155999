import { useState } from 'react'
import { Container } from '../interfaces/IceCreamTypes'

interface IToppingItem {
    item: any
    price: string
    onChange: Function
    name: string
}

export const ToppingItem = ({ item, price, onChange, name }: IToppingItem) => {
    return (
        <label>
            <div className={`d-flex justify-content-between ${item.active}`}>
                <div className="topping">{item.topping}</div>
                <div>
                    <span className="topping-price">{price}</span>
                    <input
                        checked={item.checked}
                        onChange={(e) => onChange(e, item)}
                        type="checkbox"
                        id={item.topping}
                    />
                </div>
            </div>
        </label>
    )
}

interface IContainerItem {
    item: Container
    price: string
    onChange: Function
    activeContainer?: string
}

const ContainerItem = ({
    item,
    price,
    onChange,
    activeContainer
}: IContainerItem) => {
    const [active, setActive] = useState('')
    return (
        <label htmlFor={item}>
            <div
                className={`d-flex justify-content-between ${active} ${activeContainer}`}
            >
                <div className="topping">{item}</div>
                <div>
                    {price}
                    <input
                        onChange={(e) => onChange(e, item, setActive)}
                        type="radio"
                        name="container"
                        id={item}
                    />
                </div>
            </div>
        </label>
    )
}

export default ContainerItem
