import OrderView from '../components/startpageviews/OrderView'
import CheckoutView from '../components/startpageviews/CheckoutView'
import CompleteView from '../components/startpageviews/CompleteView'
import StartView from '../components/startpageviews/StartView'
import CategoryView from '../components/startpageviews/CategoryView'
import Header from '../components/Header'

export interface IComponentItems {
    component: JSX.Element
    componentAdmin?: JSX.Element
    header?: JSX.Element
    headerAdmin?: JSX.Element
    show: boolean
    backgroundColor?: string
}

export const componentItems: IComponentItems[] = [
    {
        component: (
            <StartView
                intro={
                    <img
                        src={'img/mjukglassbaren-logo.svg'}
                        alt="mjukglassbaren-logo"
                        className="logo-big mb-5"
                    />
                }
            />
        ),
        componentAdmin: (
            <StartView
                intro={
                    <div>
                        <h1>ADMIN</h1>
                        <h4>Beställning för kassapersonal</h4>
                    </div>
                }
            />
        ),
        show: true
    },
    {
        component: <CategoryView />,
        header: <></>,
        headerAdmin: (
            <Header title="ADMIN" description="Beställning för kassapersonal" />
        ),
        show: false
    },
    {
        component: <OrderView />,
        header: <Header title="Mjukglass" description="Vad är du sugen på?" />,
        headerAdmin: (
            <Header title="ADMIN" description="Beställning för kassapersonal" />
        ),
        show: false
    },
    {
        component: <CheckoutView />,
        header: (
            <Header
                title="Slutför beställning"
                description="Kontrollera att allt stämmer"
            />
        ),
        headerAdmin: (
            <Header title="ADMIN" description="Beställning för kassapersonal" />
        ),
        show: false,
        backgroundColor: '#fff'
    },
    {
        component: <CompleteView />,
        show: false
    }
]
