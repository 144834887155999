import { Close } from '@material-ui/icons'
import { useCallback, useContext, useState } from 'react'
import OrderContext from '../../contexts/OrderContext'
import { Category, Container, Topping } from '../../interfaces/IceCreamTypes'
import ContainerItem, { ToppingItem } from '../ToppingItem'
import { ICheckedTopping } from '../../interfaces/topping'
import HR from './HR'
import OverlayButtons from './OverlayButtons'
import NoToppingItem from './NoToppingItem'
import { categoryItems } from '../../constants/categoryItems'

interface ICustomizableOverlaySoftIceCream {
    overlayEl: React.RefObject<HTMLDivElement>
    setOpenToppings: React.Dispatch<React.SetStateAction<boolean>>
    setTotalTopping: React.Dispatch<React.SetStateAction<number>>
    setToppingErrorMsg: React.Dispatch<React.SetStateAction<string>>
    setTotalContainer: React.Dispatch<React.SetStateAction<number>>
    total: number
    totalTopping: number
    checkedToppings: ICheckedTopping[]
    setCheckedToppings: React.Dispatch<React.SetStateAction<ICheckedTopping[]>>
}
const CustomizableOverlaySoftIceCream = ({
    overlayEl,
    setOpenToppings,
    setTotalTopping,
    setToppingErrorMsg,
    setTotalContainer,
    total,
    totalTopping,
    checkedToppings,
    setCheckedToppings
}: ICustomizableOverlaySoftIceCream) => {
    const { selectedItems, setSelectedItems, setTransition, toppingPrice } =
        useContext(OrderContext)

    const [errorMsg, setErrorMsg] = useState('')

    const [toppingClick, setToppingClick] = useState<number>(0)

    const [container, setContainer] = useState<Container | undefined>()
    const [topping, setTopping] = useState<Topping[]>([])

    const [activeWaffle, setActiveWaffle] = useState('')
    const [activeCup, setActiveCup] = useState('')

    const [noToppingChecked, setNoToppingChecked] = useState(true)

    const [toppingPriceStr, setToppingPriceStr] = useState(`+${toppingPrice}kr`)

    function onChangeContainer(e: any, chosenContainer: Container) {
        chosenContainer === Container.Waffle
            ? setActiveWaffle('active')
            : setActiveCup('active')

        if (container) {
            container === Container.Waffle
                ? setActiveWaffle('')
                : setActiveCup('')
            setContainer(chosenContainer)
            return
        }
        setContainer(chosenContainer)
        setTotalContainer(categoryItems[0].items[0].price)
    }

    const onChangeTopping = useCallback(
        (e: any, checkedTopping: any) => {
            let totalTopp = totalTopping
            let newToppingArr = topping
            let newCheckedToppingArr = checkedToppings
            if (e.target.checked) {
                // if noTopping is checked remove it from topping and checkedToppingArr
                if (newToppingArr.find((x) => x === Topping.NoSprinkles)) {
                    newCheckedToppingArr = newCheckedToppingArr.map((i) => {
                        if (i.topping === newToppingArr[0]) {
                            i.checked = false
                            i.active = ''
                        }
                        return i
                    })
                    const index = newToppingArr.indexOf(newToppingArr[0])
                    if (index > -1) {
                        newToppingArr.splice(index, 1)
                    }
                }
                if (newToppingArr.length > 0) {
                    // if a topping is already checked, remove that topping before add the new
                    setToppingClick(toppingClick + 1)
                    newCheckedToppingArr = newCheckedToppingArr.map((i) => {
                        if (i.topping === newToppingArr[0]) {
                            i.checked = false
                            i.active = ''
                        }
                        return i
                    })

                    const index = newToppingArr.indexOf(newToppingArr[0])
                    if (index > -1) {
                        newToppingArr.splice(index, 1)
                    }
                    totalTopp = totalTopp - toppingPrice
                    if (toppingClick < 1) {
                        setToppingErrorMsg('Du kan max välja en topping!')
                    }
                }

                setNoToppingChecked(false)
                let newArr = newCheckedToppingArr.map((i) => {
                    if (i === checkedTopping) {
                        i.checked = true
                        i.active = 'active'
                    }
                    return i
                })
                setCheckedToppings(newArr)

                let newTopp = newToppingArr.filter(
                    (i) => i !== Topping.NoSprinkles
                )
                setTopping([...newTopp, checkedTopping.topping])
                totalTopp = totalTopp + toppingPrice
                setTotalTopping(totalTopp)
            } else {
                let newArr = newCheckedToppingArr.map((i) => {
                    if (i === checkedTopping) {
                        i.checked = false
                        i.active = ''
                    }
                    return i
                })
                setCheckedToppings(newArr)
                setTopping(
                    newToppingArr.filter((i) => i !== checkedTopping.topping)
                )
                totalTopp = totalTopp - toppingPrice
                setTotalTopping(totalTopp)
            }
        }, //eslint-disable-next-line
        [topping, checkedToppings, toppingClick, totalTopping]
    )

    function addIceCream() {
        if (!container) {
            setErrorMsg('Vänligen välj hur du vill ha din glass serverad')
            return
        }
        setSelectedItems([
            ...selectedItems!,
            {
                container: container,
                toppings: topping,
                category: Category.SoftIceCream,
                price: total
            }
        ])
        closeOverlay()
        setTransition(true)
    }

    async function closeOverlay() {
        let newCheckedToppingArr = checkedToppings
        newCheckedToppingArr = newCheckedToppingArr.map((i) => {
            i.checked = false
            i.active = ''
            return i
        })
        setCheckedToppings(newCheckedToppingArr)
        setTotalContainer(0)
        setTotalTopping(0)
        setTopping([])
        await setOpenToppings(false)
        if (overlayEl !== null) {
            overlayEl.current?.setAttribute('aria-hidden', 'true')
        }
        document.body.classList.remove('noscroll')
    }

    return (
        <div
            ref={overlayEl}
            className="choise-two p-3"
            aria-hidden="true"
            id="choise-two"
        >
            <div className="topping-container d-flex flex-column justify-content-center">
                <div className="position-relative">
                    <Close
                        onClick={() => closeOverlay()}
                        style={{ fontSize: 25 }}
                    />
                    <HR text="Välj tillbehör" />
                </div>
                <NoToppingItem
                    noToppingChecked={noToppingChecked}
                    setNoToppingChecked={setNoToppingChecked}
                    setTopping={setTopping}
                    setTotalTopping={setTotalTopping}
                    setCheckedToppings={setCheckedToppings}
                    checkedToppings={checkedToppings}
                />
                <>
                    <HR text="Servering" />
                    <ContainerItem
                        item={Container.Waffle}
                        price={`${categoryItems[0].items[0].price} kr`}
                        onChange={onChangeContainer}
                        activeContainer={activeWaffle}
                    />
                    <ContainerItem
                        item={Container.Cup}
                        price={`${categoryItems[0].items[0].price} kr`}
                        onChange={onChangeContainer}
                        activeContainer={activeCup}
                    />
                </>
                <div className="position-relative d-flex flex-column justify-content-center">
                    {container === undefined && (
                        <div className="topping-overlay position-absolute"></div>
                    )}
                    <HR text="Strössel" />
                    {/* <div className="text-center topping-offer">
                            Just nu bjuder vi på topping!
                        </div> */}
                    {checkedToppings
                        .filter((x) => x.isSprinkle === true)
                        .map((i, key) => {
                            return (
                                <ToppingItem
                                    key={key}
                                    item={i}
                                    price={toppingPriceStr}
                                    onChange={onChangeTopping}
                                    name={i.topping}
                                />
                            )
                        })}
                    {container === Container.Cup && (
                        <>
                            <HR text="Såser" />
                            {checkedToppings
                                .filter((x) => x.isSprinkle === false)
                                .map((i, key) => {
                                    return (
                                        <>
                                            {i.topping ===
                                                Topping.ChocolateDip ||
                                            i.topping ===
                                                Topping.NoSprinkles ? (
                                                <></>
                                            ) : (
                                                <ToppingItem
                                                    key={key}
                                                    item={i}
                                                    price={toppingPriceStr}
                                                    onChange={onChangeTopping}
                                                    name={i.topping}
                                                />
                                            )}
                                        </>
                                    )
                                })}
                        </>
                    )}
                    <HR />
                    {container === Container.Waffle && (
                        <>
                            <ToppingItem
                                item={checkedToppings.find(
                                    (x) => x.topping === Topping.ChocolateDip
                                )}
                                price={toppingPriceStr}
                                onChange={onChangeTopping}
                                name="ChocolateDipLight"
                            />
                        </>
                    )}
                </div>
                <OverlayButtons
                    total={total}
                    errorMsg={errorMsg}
                    closeOverlay={closeOverlay}
                    addIceCream={addIceCream}
                />
            </div>
        </div>
    )
}
export default CustomizableOverlaySoftIceCream
