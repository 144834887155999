import { IIceCream } from '../../interfaces/i-icecream'
import { Add } from '@material-ui/icons'
import { useEffect, useRef, useState } from 'react'
import CustomizableOverlayGutesnurr from './CustomizableOverlayGutesnurr'

interface ICustomizableGutesnurr {
    item: IIceCream
}
const CustomizableGutesnurr = ({ item }: ICustomizableGutesnurr) => {
    const [openToppings, setOpenToppings] = useState(false)
    const [total, setTotal] = useState<number>(0)
    const [totalTopping, setTotalTopping] = useState<number>(0)

    const [totalConatiner, setTotalContainer] = useState<number>(0)
    const [toppingErrorMsg, setToppingErrorMsg] = useState('')

    let overlayEl = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setTotal(60 + totalTopping)
        //eslint-disable-next-line
    }, [totalTopping, totalConatiner])

    async function openOverlay() {
        await setOpenToppings(true)
        if (overlayEl !== null) {
            overlayEl.current?.setAttribute('aria-hidden', 'false')
        }
        document.body.classList.add('noscroll')
    }

    return (
        <div className="icecream-item">
            <div onClick={() => openOverlay()}>
                <div
                    className="img-container"
                    style={{
                        backgroundImage: `url('${item.img}')`
                    }}
                >
                    <div className="price">{`${item.price}`}</div>
                </div>
                <div className="d-flex justify-content-between align-items-center info-container p-3">
                    <div className="title-desc">
                        <h3>{item.title}</h3>
                        <div>{item.description}</div>
                    </div>
                </div>
            </div>
            {openToppings && (
                <CustomizableOverlayGutesnurr
                    item={item}
                    overlayEl={overlayEl}
                    setOpenToppings={setOpenToppings}
                    setTotalTopping={setTotalTopping}
                    setToppingErrorMsg={setToppingErrorMsg}
                    setTotalContainer={setTotalContainer}
                    total={total}
                    totalTopping={totalTopping}
                />
            )}
            {toppingErrorMsg && (
                <div className="topping-error d-flex justify-content-center align-items-center flex-column">
                    {toppingErrorMsg}
                    <button
                        className="btn btn-outline-primary px-5 mt-3"
                        onClick={() => setToppingErrorMsg('')}
                    >
                        OK
                    </button>
                </div>
            )}
        </div>
    )
}
export default CustomizableGutesnurr
