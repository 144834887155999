import { useState } from 'react'
import { Topping } from '../../interfaces/IceCreamTypes'
import { ICheckedTopping } from '../../interfaces/topping'

interface INoToppingItem {
    noToppingChecked: boolean
    setNoToppingChecked: React.Dispatch<React.SetStateAction<boolean>>
    setTopping: React.Dispatch<React.SetStateAction<Topping[]>>
    setTotalTopping: React.Dispatch<React.SetStateAction<number>>
    setCheckedToppings: React.Dispatch<React.SetStateAction<ICheckedTopping[]>>
    checkedToppings: ICheckedTopping[]
}
const NoToppingItem = ({
    noToppingChecked,
    setNoToppingChecked,
    setTopping,
    setTotalTopping,
    setCheckedToppings,
    checkedToppings
}: INoToppingItem) => {
    return (
        <label>
            <div
                className={`d-flex justify-content-between ${
                    noToppingChecked ? 'active' : ''
                }`}
            >
                <div className="topping">Ingen topping</div>
                <div>
                    0kr
                    <input
                        checked={noToppingChecked}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setNoToppingChecked(true)
                                let newArr = checkedToppings.map((i) => {
                                    i.checked = false
                                    i.active = ''
                                    return i
                                })
                                setCheckedToppings(newArr)
                                setTopping([Topping.NoSprinkles])
                                setTotalTopping(0)
                                return
                            }
                            setTopping([Topping.NoSprinkles])
                            setNoToppingChecked(false)
                        }}
                        type="checkbox"
                    />
                </div>
            </div>
        </label>
    )
}
export default NoToppingItem
