import { IIceCream } from '../../interfaces/i-icecream'
import { Add } from '@material-ui/icons'
import { useEffect, useRef, useState } from 'react'
import CustomizableOverlaySoftIceCream from './CustomizableOverlaySoftIceCream'
import { Topping } from '../../interfaces/IceCreamTypes'
import { ICheckedTopping } from '../../interfaces/topping'

interface ICustomizableSoftIceCream {
    item: IIceCream
}
const CustomizableSoftIceCream = ({ item }: ICustomizableSoftIceCream) => {
    const [openToppings, setOpenToppings] = useState(false)
    const [total, setTotal] = useState<number>(0)
    const [totalTopping, setTotalTopping] = useState<number>(0)

    const [totalConatiner, setTotalContainer] = useState<number>(0)
    const [toppingErrorMsg, setToppingErrorMsg] = useState('')
    // move to context
    // TODO create a loop
    // testa  beställ i admin
    const [checkedToppings, setCheckedToppings] = useState<ICheckedTopping[]>(
        Object.entries(Topping).map(([key, value]) => {
            return {
                active: '',
                checked: false,
                topping: value,
                isSprinkle: isSprinkle(value)
            }
        })
    )
    // flytta för att kunna återanvända
    function isSprinkle(topping: Topping) {
        switch (topping) {
            case Topping.RaspberrySprinkles:
            case Topping.ChocolateSprinkles:
            case Topping.CandiedHazelnuts:
            case Topping.SaltedCaramelCookies:
            case Topping.RockyCookies:
            case Topping.SaltLicoriceFlakes:
            case Topping.PeppermintCandyCane:
            case Topping.FruitSprinkles:
            case Topping.Cinnamon:
            case Topping.RoastedCoconut:
                return true
            case Topping.Salmberry:
            case Topping.ChocolateSauce:
            case Topping.CaramelSauce:
            case Topping.Lemoncurd:
            case Topping.RaspberrySauce:
            case Topping.StrawberrySauce:
                return false
            default:
                return false
        }
    }

    // const [checkedToppings, setCheckedToppings] = useState<ICheckedTopping[]>([
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.RaspberrySprinkles,
    //         isSprinkle: true
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.ChocolateSprinkles,
    //         isSprinkle: true
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.CandiedHazelnuts,
    //         isSprinkle: true
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.SaltedCaramelCookies,
    //         isSprinkle: true
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.RockyCookies,
    //         isSprinkle: true
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.SaltLicoriceFlakes,
    //         isSprinkle: true
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.PeppermintCandyCane,
    //         isSprinkle: true
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.FruitSprinkles,
    //         isSprinkle: true
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.Cinnamon,
    //         isSprinkle: true
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.RoastedCoconut,
    //         isSprinkle: false
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.Salmberry,
    //         isSprinkle: false
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.ChocolateSauce,
    //         isSprinkle: false
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.CaramelSauce,
    //         isSprinkle: false
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.Lemoncurd,
    //         isSprinkle: false
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.RaspberrySauce,
    //         isSprinkle: false
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.StrawberrySauce,
    //         isSprinkle: false
    //     },
    //     {
    //         active: '',
    //         checked: false,
    //         topping: Topping.ChocolateDip,
    //         isSprinkle: false
    //     }
    // ])

    let overlayEl = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setTotal(totalConatiner + totalTopping)
        //eslint-disable-next-line
    }, [totalTopping, totalConatiner])

    async function openOverlay() {
        await setOpenToppings(true)
        if (overlayEl !== null) {
            overlayEl.current?.setAttribute('aria-hidden', 'false')
        }
        document.body.classList.add('noscroll')
    }

    return (
        <div className="icecream-item">
            <div onClick={() => openOverlay()}>
                <div
                    className="img-container"
                    style={{
                        backgroundImage: `url('${item.img}')`
                    }}
                >
                    <div className="price">{`${item.price}`}</div>
                </div>
                <div className="d-flex justify-content-between align-items-center info-container p-3">
                    <div className="title-desc">
                        <h3>{item.title}</h3>
                        <div>{item.description}</div>
                    </div>

                    <Add style={{ fontSize: 35 }} />
                </div>
            </div>
            {openToppings && (
                <CustomizableOverlaySoftIceCream
                    overlayEl={overlayEl}
                    setOpenToppings={setOpenToppings}
                    setTotalTopping={setTotalTopping}
                    setToppingErrorMsg={setToppingErrorMsg}
                    setTotalContainer={setTotalContainer}
                    total={total}
                    totalTopping={totalTopping}
                    checkedToppings={checkedToppings}
                    setCheckedToppings={setCheckedToppings}
                />
            )}
            {toppingErrorMsg && (
                <div className="topping-error d-flex justify-content-center align-items-center flex-column">
                    {toppingErrorMsg}
                    <button
                        className="btn btn-outline-primary px-5 mt-3"
                        onClick={() => setToppingErrorMsg('')}
                    >
                        OK
                    </button>
                </div>
            )}
        </div>
    )
}
export default CustomizableSoftIceCream
