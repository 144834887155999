import { useContext, useEffect, useRef, useState } from 'react'
import { Undo, ShoppingCart } from '@material-ui/icons'
import OrderContext from '../contexts/OrderContext'
import { componentItems } from '../constants/componentItems'
import { CSSTransition } from 'react-transition-group'

function Footer() {
    const {
        goBack,
        component,
        componentItemsActive,
        changeComponent,
        selectedItems,
        transition,
        setTransition
    } = useContext(OrderContext)
    const [hideButtons, setHideButtons] = useState(false)

    useEffect(() => {
        if (component.component !== componentItemsActive[4].component) {
            setHideButtons(false)
        }
        if (componentItemsActive[4].component === component.component) {
            setHideButtons(true)
        }
    }, [hideButtons, component, componentItemsActive])

    const nodeRef = useRef(null)

    useEffect(() => {
        setTransition(false)
    }, [selectedItems])

    return (
        <footer
            className="d-flex justify-content-between align-items-center"
            id="footer"
        >
            {!hideButtons && (
                <Undo onClick={() => goBack()} style={{ fontSize: 25 }} />
            )}
            <div className="logo-container">
                <img
                    src="img/GG_Logo_2019_GG.svg"
                    alt="footer-logo"
                    className="logo"
                    onClick={() => changeComponent(componentItems[0])}
                />
            </div>
            {!hideButtons && (
                <div>
                    {selectedItems.length > 0 && (
                        // <TransitionGroup className="">
                        //     {selectedItems.map((i, key) => (
                        <CSSTransition
                            // key={key}
                            in={transition}
                            nodeRef={nodeRef}
                            timeout={300}
                            classNames="alert"
                            // unmountOnExit
                            // onExited={() => setTransition(false)}
                        >
                            <span
                                ref={nodeRef}
                                className="items-notification alert-enter"
                            >
                                {selectedItems.length}
                            </span>
                        </CSSTransition>
                        //     ))}
                        // </TransitionGroup>
                    )}
                    <ShoppingCart
                        onClick={() => changeComponent(componentItems[3])}
                        style={{ fontSize: 25 }}
                    />
                </div>
            )}
        </footer>
    )
}

export default Footer
