interface IHeader {
    title: string
    description: string
}

function Header({ title, description }: IHeader) {
    return <></>
}

export default Header
