import {
    useState,
    createContext,
    useCallback,
    Context,
    PropsWithChildren,
    useEffect
} from 'react'
import { componentItems, IComponentItems } from '../constants/componentItems'
import { IOrderItem, IOrder } from '../interfaces/i-order'
import { ICategory } from '../constants/categoryItems'
import { auth } from '../firebase'

interface IOrderContext {
    component: IComponentItems
    componentItemsActive: IComponentItems[]
    selectedItems: IOrderItem[]
    chosenCategory: ICategory | undefined
    order: IOrder | undefined
    setChosenCategory: Function
    setOrder: Function
    setSelectedItems: Function
    changeComponent: Function
    goBack: Function
    transition: boolean
    setTransition: Function
    toppingPrice: number
    isLoggedIn: boolean
}

const OrderContext: Context<IOrderContext> = createContext<IOrderContext>({
    component: {
        component: <></>,
        show: false
    },
    componentItemsActive: [],
    selectedItems: [],
    chosenCategory: undefined,
    order: undefined,
    setChosenCategory: () => {},
    setOrder: () => {},
    setSelectedItems: () => {},
    changeComponent: () => {},
    goBack: () => {},
    transition: false,
    setTransition: () => {},
    toppingPrice: 0,
    isLoggedIn: false
})

export const OrderContextProvider = ({ children }: PropsWithChildren<any>) => {
    const [component, setComponent] = useState<IComponentItems>(
        componentItems[0]
    )
    const [componentItemsActive, setComponentItemsActive] =
        useState<IComponentItems[]>(componentItems)

    const [selectedItems, setSelectedItems] = useState<IOrderItem[]>(
        JSON.parse(localStorage.getItem('items') || '[]')
    )
    const [order, setOrder] = useState<IOrder | undefined>()
    const [chosenCategory, setChosenCategory] = useState<
        ICategory | undefined
    >()

    const [transition, setTransition] = useState(false)

    const [toppingPrice, setToppingPrice] = useState(0)

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const urlPath = window.location.pathname

    auth.onAuthStateChanged(function (user: any) {
        if (user && urlPath.includes('guteadminorder')) {
            setIsLoggedIn(true)
            setToppingPrice(10)
        }
    })

    useEffect(() => {
        localStorage.setItem('items', JSON.stringify(selectedItems))
    }, [selectedItems])

    const changeComponent = useCallback(
        (comp: IComponentItems) => {
            const updatedArray = componentItemsActive.map((a) => {
                if (a.show === true) {
                    a.show = false
                }
                if (a === comp) {
                    a.show = true
                }
                return a
            })
            setComponentItemsActive(updatedArray)
            setComponent({ ...comp, show: true })
        },
        [componentItemsActive]
    )

    const goBack = useCallback(() => {
        var index = componentItemsActive.findIndex((e) => e.show === true)
        const updatedArray = componentItemsActive.map((a) => {
            if (a.show === true) {
                a.show = false
            }
            return a
        })
        if (index === 0 || index === -1) {
            changeComponent(componentItems[0])
            return
        }
        if (index === 3) {
            changeComponent(componentItems[1])
            return
        }
        var previous =
            componentItemsActive[
                index === 0 ? componentItemsActive.length - 1 : index - 1
            ]
        setComponentItemsActive(updatedArray)
        setComponent({
            ...previous,
            show: true
        })
    }, [changeComponent, componentItemsActive])

    return (
        <OrderContext.Provider
            value={{
                component,
                componentItemsActive,
                chosenCategory,
                selectedItems,
                order,
                setChosenCategory,
                setOrder,
                setSelectedItems,
                changeComponent,
                goBack,
                transition,
                setTransition,
                toppingPrice,
                isLoggedIn
            }}
        >
            {children}
        </OrderContext.Provider>
    )
}
export default OrderContext
