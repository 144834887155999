import AdminHeader from '../components/admin/AdminHeader'
import ContentBox from '../components/admin/ContentBox'
import ControlPanelMenu from '../components/admin/controlPanel/ControlPanelMenu'
import PrivateRoute from '../components/PrivateRoute'
import CustomerMessage from '../components/admin/controlPanel/CustomerMessage'
import { Switch } from 'react-router-dom'
import Statistics from '../components/admin/controlPanel/Statistics/Statistics'

interface ControlPanelProps {}
const ControlPanel = () => {
    return (
        <>
            <div className="admin page py-3 control-panel">
                <AdminHeader />
                <div className="container-xxl pt-5 mt-5">
                    <div className="row row-cols-1 row-cols-md-2">
                        <div>
                            <h2>Kontrollpanel</h2>
                        </div>
                        <div className="col-md-9">
                            <Switch>
                                <PrivateRoute
                                    children={
                                        <ContentBox>
                                            <CustomerMessage />
                                        </ContentBox>
                                    }
                                    path="/gutekontrollpanel/meddelande"
                                />
                                <PrivateRoute
                                    children={
                                        <ContentBox>
                                            <Statistics />
                                        </ContentBox>
                                    }
                                    path="/gutekontrollpanel/statistik"
                                />
                                <PrivateRoute
                                    children={
                                        <ContentBox>
                                            <div>Navigera i sidomenyn</div>
                                        </ContentBox>
                                    }
                                    path="/gutekontrollpanel"
                                />
                            </Switch>
                        </div>
                        <div className="col-md-3">
                            <ContentBox>
                                <ControlPanelMenu />
                            </ContentBox>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ControlPanel
