import { useCallback } from 'react'
import { Schedule } from '@material-ui/icons'
import { IOrder } from '../../interfaces/i-order'
import AdminOrderItem from './AdminOrderItem'
import adminService from '../../services/adminService'

interface INewAdminItems {
    orders: IOrder[]
    setOrders: Function
}
const NewAdminItems = ({ orders, setOrders }: INewAdminItems) => {
    const updateOrder = useCallback(
        async (ord: IOrder, setError: Function) => {
            try {
                await adminService.updateorder(ord)
                setError(undefined)
                setOrders(orders.filter((i) => i !== ord))
            } catch (error: any) {
                setError(
                    new Error(`Något gick fel, försök igen. ${error.message}`)
                )
            }
        },
        [orders, setOrders]
    )

    const setItemToReady = useCallback(
        async (order: IOrder, setError) => {
            order.isReady = true
            let newArr: IOrder[] = orders
            newArr.map((el) =>
                el.id === order.id ? Object.assign({}, order) : el
            )
            setOrders(newArr)

            try {
                await adminService.updateorder(order)
                setError(undefined)
            } catch (error: any) {
                setError(
                    new Error(
                        `Något gick fel vid uppdatering av order. ${error.message}`
                    )
                )
            }
        },
        [orders, setOrders]
    )

    const cancelOrder = useCallback(
        async (order: IOrder, setError) => {
            order.isCanceled = true
            let newArr: IOrder[] = orders
            newArr.map((el) =>
                el.id === order.id ? Object.assign({}, order) : el
            )
            setOrders(newArr)

            await updateOrder(order, setError)
        },
        [orders, setOrders, updateOrder]
    )

    const setItemToPickedUp = useCallback(
        async (order: IOrder, setError) => {
            order.isPickedUp = true
            let newArr: IOrder[] = orders
            newArr.map((el) =>
                el.id === order.id ? Object.assign({}, order) : el
            )
            setOrders(newArr)
            try {
                await adminService.updateorder(order)
                setError(undefined)
            } catch (error: any) {
                setError(
                    new Error(
                        `Något gick fel vid uppdatering av order. ${error.message}`
                    )
                )
            }
        },
        [orders, setOrders]
    )

    return (
        <div className="my-4 new-orders">
            <div className="table-head d-flex mb-2">
                <Schedule className="mx-3" style={{ fontSize: 25 }} />
                <div className="me-auto ps-4 name">Namn</div>
                <div className="px-2">Order nr.</div>
            </div>
            {orders?.map((i, key) => {
                return (
                    <AdminOrderItem
                        key={key}
                        item={i}
                        setItemToReady={setItemToReady}
                        setItemToPickedUp={setItemToPickedUp}
                        cancelOrder={cancelOrder}
                    />
                )
            })}
        </div>
    )
}
export default NewAdminItems
