import { useEffect, useState } from 'react'
import StatisticsObject from './StatisticsObject'
import { getToken } from '../../../../services/adminService'

interface StatisticsProps {}
interface IStatisticsData {
    totalOrders: number
    totalOrdersMonths: object
    totalCategories: object
    totalFlavorGutesnurr: object
    totalFlavorMilkshake: object
    totalFlavorIceCreamMenu: object
    totalTopping: object
    totalPaidWithSwish: number
}

interface IStatisticsStrings {
    [key: string]: string
}

const strings: IStatisticsStrings = {
    totalOrders: 'Totalt antal ordrar',
    totalOrdersMonths: 'Totalt antal ordrar varje månad',
    totalCategories: 'Totalt antal av varje kategori',
    totalFlavorGutesnurr: 'Totalt antal varje Gutesnurr såld',
    totalFlavorMilkshake: 'Totalt antal av varje Milkshake såld',
    totalFlavorIceCreamMenu: 'Totalt antal av varje Glassmeny såld',
    totalTopping: 'Totalt antal av varje topping',
    totalPaidWithSwish: 'Totalt antal ordrar som betalats i appen'
}

const Statistics: React.FC<StatisticsProps> = () => {
    const [statistics, setStatistics] = useState<IStatisticsData>({
        totalOrders: 0,
        totalOrdersMonths: {},
        totalCategories: {},
        totalFlavorGutesnurr: {},
        totalFlavorMilkshake: {},
        totalFlavorIceCreamMenu: {},
        totalTopping: {},
        totalPaidWithSwish: 0
    })
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_GUTEGLASSAPI_DOMAIN}/Admin/statistics`,
                    {
                        headers: {
                            'Content-Type': 'text/html; charset=utf-8',
                            Authorization: `Bearer ${await getToken()}`
                        }
                    }
                )

                if (!response.ok) {
                    throw new Error(
                        'Network response was not ok while fetching message data'
                    )
                }

                const data = await response.json()
                setStatistics(data)
                return data
            } catch (error) {
                console.error(error)
            }
        }
        fetchData()
    }, [])

    return (
        <>
            <div className="pb-3">
                <h4>Statistik</h4>
            </div>
            <ul className="list-group">
                {Object.entries(strings).map(([key, value]) => {
                    return (
                        <div key={key}>
                            {(key === 'totalOrders' ||
                                key === 'totalPaidWithSwish') && (
                                <li className="list-group-item p-3">
                                    {value}: {statistics[key]}
                                </li>
                            )}
                            {(key === 'totalOrdersMonths' ||
                                key === 'totalCategories' ||
                                key === 'totalFlavorGutesnurr' ||
                                key === 'totalFlavorMilkshake' ||
                                key === 'totalFlavorIceCreamMenu' ||
                                key === 'totalTopping') && (
                                <li className="list-group-item p-3">
                                    {value}:{' '}
                                    <StatisticsObject obj={statistics[key]} />
                                </li>
                            )}
                        </div>
                    )
                })}
            </ul>
        </>
    )
}

export default Statistics
