import { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'

interface CustomerMessageProps {}

export const Popup: React.FunctionComponent = (): React.ReactElement | null => {
    const [show, setShow] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [hideButton, setHideButton] = useState<boolean>(false)
    useEffect(() => {
        fetch(`${process.env.REACT_APP_GUTEGLASSAPI_DOMAIN}/Order/message`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }

                return response.text()
            })
            .then((data: string) => {
                setHideButton(data.includes('#') ? true : false)
                setMessage(data.replace('#', ''))
                setShow(data.length > 0 ? true : false)
            })
            .catch((error: Error) => console.error('Error:', error))
    }, [])

    return (
        <CSSTransition in={show} timeout={300} classNames="popup" unmountOnExit>
            {show ? (
                <div className="popup overlay">
                    <div className="popup--inner p-4">
                        <div>
                            <h2 className="popup--inner--heading display-2 font-weight-bold">
                                Titta hit!
                            </h2>
                            <p className="popup--inner--text">{message}</p>
                        </div>
                        {!hideButton && (
                            <div>
                                <button
                                    onClick={() => setShow(false)}
                                    type="button"
                                    className="btn btn-dark"
                                >
                                    Okej!
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="d-none"></div>
            )}
        </CSSTransition>
    )
}
const CustomerMessage: React.FunctionComponent<CustomerMessageProps> = () => {
    return (
        <>
            <div className="customer-message">
                <div className="customer-message__icon">
                    <i className="fas fa-check-circle"></i>
                </div>
                <div className="customer-message__text">
                    <h3>Order Completed</h3>
                    <p>
                        Thank you for your order. Your order has been completed
                        and will be shipped soon.
                    </p>
                </div>
            </div>
        </>
    )
}
